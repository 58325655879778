import { createRouter, createWebHashHistory } from 'vue-router'
import Login from '@/views/login/'
import Vote from '@/views/vote/'
import Register from '@/views/register/'
import Voted from '@/views/voted/'
import Result from '@/views/result/'
import Home from '@/views/home/'
import PageNotFound from '@/views/404/'
import { useStore } from '@/stores/store.js'
import { getUserFromRTDB, getVote } from '@/lib/firebase/index.js'
// import { error } from 'logrocket'

const metaData = {
  title: 'VP Awards 2024',
  metaTags: [
    {
      name: 'description',
      content: 'VP Awards 2024',
    },
    {
      property: 'og:site_name',
      content: 'VP Awards 2024',
    },
    {
      property: 'og:image',
      content:
        'https://storage.googleapis.com/fourplus-media-bucket/vp-awards/2024/images/banners/vp-awards-og-banner.jpg',
    },
    {
      property: 'og:image:url',
      content:
        'https://storage.googleapis.com/fourplus-media-bucket/vp-awards/2024/images/banners/vp-awards-og-banner.jpg',
    },
    {
      property: 'og:image:secure_url',
      content:
        'https://storage.googleapis.com/fourplus-media-bucket/vp-awards/2024/images/banners/vp-awards-og-banner.jpg',
    },
    {
      property: 'og:image:type',
      content: 'image/jpeg',
    },
    {
      property: 'og:image:width',
      content: '600',
    },
    {
      property: 'og:image:height',
      content: '400',
    },
    {
      property: 'og:image:alt',
      content: 'VP Awards 2024 Banner ',
    },
  ],
}
// import LogRocket from 'logrocket'

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login,
    meta: metaData,
    beforeEnter: (to, from, next) => {
      const store = useStore()
      const { errorStatus, errorHeadline, errorMessage } = to.query
      if (errorStatus) {
        store.error = { status: errorStatus, headline: errorHeadline, message: errorMessage }
        return next()
      }
      next()
    },
  },
  {
    path: '/vote',
    name: 'vote',
    component: Vote,
    meta: metaData,
    beforeEnter: (to, from, next) => {
      console.log('to and from', to, from)
      const store = useStore()
      // console.log('parent vote route', to.params)
      if (!to.params.uid) {
        // TODO : Add Error Control for No User/UID
        return next({ name: 'login', query: { error: 'invalid' } })
      }

      if (from.name === 'login' && to.params.uid) {
        console.log('from login with uid', to.params.uid)
        store.user.vote.length = 0

        return next()
      }
      if (from.name !== 'login' && to.params.uid) {
        // console.log('NOT from login but with uid')
        return next()
      }
      // console.log('none of the if conditions applied...')
    },
    children: [
      {
        path: ':uid',
        name: 'uid',
        component: Vote,
        meta: metaData,
        beforeEnter: async (to, from, next) => {
          console.log('child id route', to.params)
          const store = useStore()
          console.log('to.params.uid', to.params.uid)
          // GET USER FROM RTDB
          const data = await getUserFromRTDB(to.params.uid)
          const user = data.user
          console.log('data', data)
          console.log('before enter -user ', user)
          if (user) {
            console.log('checking if user is created', user)
            // store.user = { ...store.user, ...user }
            store.user = { ...user }
            return next()
          }

          // GET VOTE
          console.log('did not find user.. so checking in votes')
          const voteData = await getVote(to.params.uid)
          const { vote } = voteData
          if (vote) {
            store.user = vote
            return next({ name: 'register' })
          }

          return next({
            name: 'login',
            query: {
              errorStatus: true,
              errorHeadline: 'Sorry',
              errorMessage: `There's an error. The user/vote is invalid (${to.params.uid}). Please share screenshot on whatsapp with +91-9920019569`,
            },
          })
        },
      },
    ],
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
    meta: metaData,
    beforeEnter: (to, from, next) => {
      // console.log('parent register', to, from)
      const store = useStore()
      if (from.name === 'vote' && store.user.uid) {
        console.log('from vote', to, from)
        // console.log('just voted')
        return next()
      }
      if (from.name === 'uid' && from.params.uid) {
        console.log('from vote', to, from)
        // console.log('just voted')
        return next()
      }
      if (to.redirectedFrom?.name === 'uid' && !store.user.isRegistered) {
        // console.log('to redirected uid voted but not registered', to, from)
        return next()
        // return next({ params: { votedButNotRegistered: true } })
      }
      if (to.redirectedFrom?.name === 'uid' && store.user.isRegistered) {
        // console.log('to redirected uid with voted and registered', to, from)
        return next()
      }
      // console.log('skipped just voting')
      return next({ name: 'login' })
    },
    children: [{ path: ':uid', name: 'register_uid', component: Register }],
  },
  {
    path: '/category/:category',
    name: 'category',
    component: Vote,
    meta: metaData,
    beforeEnter: (to, from, next) => {
      const store = useStore()
      // to ensure a previosly logged in user is not able to vote
      store.user = { vote: [] }
      // get current slide from url

      const categories = [
        '01',
        '02',
        '03',
        '04',
        '05',
        '06',
        '07',
        '08',
        '09',
        '10',
        '11',
        '12',
        '13',
        '14',
        '15',
        '16',
        '17',
      ]

      if (!categories.includes(to.params.category)) {
        router.push({
          name: 'page-not-found',
          params: { pathMatch: to.path.substring(1).split('/') },
          query: to.query,
          hash: to.hash,
        })
      }
      store.currentSlide = +to.params.category
      next()
    },
  },
  {
    path: '/nomination/:nomination',
    name: 'nomination',
    component: Vote,
    meta: metaData,
    beforeEnter: (to, from, next) => {
      const store = useStore()
      // to ensure a previosly logged in user is not able to vote
      store.user = { vote: [] }
      // get vote (and category degails) from url
      const vote = to.params.nomination.split('-')
      const [category, product] = vote
      const categories = [
        '01',
        '02',
        '03',
        '04',
        '05',
        '06',
        '07',
        '08',
        '09',
        '10',
        '11',
        '12',
        '13',
        '14',
        '15',
        '16',
        '17',
      ]

      const products = ['01', '02', '03', '04', '05']

      if (!categories.includes(category) || !products.includes(product)) {
        router.push({
          name: 'page-not-found',
          params: { pathMatch: to.path.substring(1).split('/') },
          query: to.query,
          hash: to.hash,
        })
      }
      console.log('category', category)
      store.currentSlide = +category
      // console.log('product', to.params.nomination)
      next()
    },
  },
  {
    path: '/voted/:uid',
    name: 'voted',
    component: Voted,
    meta: metaData,
    beforeEnter: async (to, from, next) => {
      const store = useStore()
      // To ensure that status.error is false by default in case the user has not triggered the close modal task which is supposed to do that
      store.error.status = false
      next()
    },
  },
  {
    path: '/result',
    name: 'result',
    component: Result,
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'page-not-found',
    component: PageNotFound,
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

// TO ADD META TAGS
// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title)

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags)

  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags)

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map((el) =>
    el.parentNode.removeChild(el)
  )

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next()

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement('meta')

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key])
      })

      // We use this to track which meta tags we create so we don't interfere with other ones.
      tag.setAttribute('data-vue-router-controlled', '')

      return tag
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag))

  next()
})

export default router
