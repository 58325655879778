<template>
  <div class="formgroup">
    <input v-bind="attrs" v-model="inputValue" @input="updateValue" />
    <label :for="attrs.id">{{ label }}</label>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'

import { useAttrs } from 'vue'

const attrs = useAttrs()
const props = defineProps({
  label: {
    type: String,
    default: '',
  },
  modelValue: {
    type: String,
    required: true,
  },
})

const inputValue = ref('')

const emit = defineEmits(['update:modelValue'])

const updateValue = () => {
  // console.log('updating..', inputValue.value)
  emit('update:modelValue', inputValue.value)
}

onMounted(() => {
  inputValue.value = props.modelValue
})
</script>
