<template>
  <article class="login__desktop">
    <figure>
      <picture>
        <!-- <source media="(min-width:50rem )" srcset="./../images/banner-mobile.jpg" /> -->
        <img src="@/assets/images/banner-desktop.jpg" alt="" />
      </picture>
    </figure>
  </article>
</template>
