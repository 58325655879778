export const nominations = [
  {
    brandName: 'SATISLOH ',
    categoryCode: '17',
    categoryName: 'VP-17 Best Value Enhancer (Lab)',
    distributor: 'Satisloh AG',
    productName: 'HYDRA BRUSH 4',
    productCode: '17-05',
    productImage: '17-05.jpg',
    pdf: '',
    price: '',
    ID: '4012435000002518295',
    brandLogo: '17-05-logo.jpg',
    productDescription:
      'A compact, fully automated soft brush system cleans both sides of ophthalmic lenses, removing contaminants like polish residue and fingerprints. It ensures lenses are perfectly clean and dry, with a capacity of 270 lenses per hour.',
    distributorEmail: 'paula.rosales@satisloh.com',
  },
  {
    brandName: 'SATISLOH',
    categoryCode: '17',
    categoryName: 'VP-17 Best Value Enhancer (Lab)',
    distributor: 'Satisloh AG',
    productName: 'SATISLOH E-TICKET SYSTEM',
    productCode: '17-04',
    productImage: '17-04.jpg',
    pdf: '',
    price: '',
    ID: '4012435000002518291',
    brandLogo: '17-04-logo.jpg',
    productDescription:
      'Provides live job status updates and visual job data in a dynamic, reusable digital format. Automatically updates and displays relevant data for each department, reducing paper and ink waste. Starting at €40K for a 1000 lpd production site',
    distributorEmail: 'paula.rosales@satisloh.com',
  },
  {
    brandName: 'Q-SHADE+ ',
    categoryCode: '17',
    categoryName: 'VP-17 Best Value Enhancer (Lab)',
    distributor: 'GKB Ophthalmics Products FZE',
    productName: 'SEMI-FINISHED Q-SHADE+ LENS',
    productCode: '17-03',
    productImage: '17-03.jpg',
    pdf: '',
    price: '',
    ID: '4012435000002518287',
    brandLogo: '17-03-logo.jpg',
    productDescription:
      'Q-Shade+ Photo lenses use advanced technology to adapt to varying light conditions. They darken in sunlight and lighten in low light, offering optimal vision and comfort in all environments.',
    distributorEmail: 'domnic.mendes@gkbvision.com',
  },
  {
    brandName: 'MEI ',
    categoryCode: '17',
    categoryName: 'VP-17 Best Value Enhancer (Lab)',
    distributor: 'MEI',
    productName: 'EASYBUDDY ',
    productCode: '17-02',
    productImage: '17-02.jpg',
    pdf: '',
    price: '',
    ID: '4012435000002518283',
    brandLogo: '17-02-logo.jpg',
    productDescription:
      'Easy Buddy is an automated system with an integrated cobot that feeds the EasyFit Trend with minimal manual intervention, boosting productivity. It handles up to five trays at the entrance and five at the exit.',
    distributorEmail: 'info@meisystem.com',
  },
  {
    brandName: 'COBURN TECHNOLOGIES ',
    categoryCode: '17',
    categoryName: 'VP-17 Best Value Enhancer (Lab)',
    distributor: 'Coburn Technologies PVT LTD',
    productName: 'CRYSTALCHROME',
    productCode: '17-01',
    productImage: '17-01.jpg',
    pdf: '17-01.pdf',
    price: '',
    ID: '4012435000002518279',
    brandLogo: '17-01-logo.jpg',
    productDescription:
      'The CrystalChrome® Photochromic Spin Coating System lets labs convert clear lenses into high-quality photochromic lenses quickly, reducing costs and inventory while delivering premium lenses efficiently.',
    distributorEmail: 'nram@coburntechnologies.com',
  },
  {
    brandName: 'ZEISS',
    categoryCode: '16',
    categoryName: 'VP-16 Best Value Enhancer (Retail)',
    distributor: 'ZEISS Vision Care Middle East \u0026 Africa',
    productName: 'VISUCORE 500',
    productCode: '16-05',
    productImage: '16-05.jpg',
    pdf: '',
    price: '',
    ID: '4012435000002518275',
    brandLogo: '16-05-logo.jpg',
    productDescription:
      'ZEISS VISUCORE 500 simplifies refraction by combining objective and subjective tests in one cycle. It maximizes efficiency and accuracy while saving space, making it ideal for any level of staff experience.',
    distributorEmail: 'marketing.vision.ae@zeiss.com',
  },
  {
    brandName: 'VISIONIX',
    categoryCode: '16',
    categoryName: 'VP-16 Best Value Enhancer (Retail)',
    distributor: 'Amico LLC',
    productName: 'VX120+ DRY EYE',
    productCode: '16-04',
    productImage: '16-04.jpg',
    pdf: '',
    price: '',
    ID: '4012435000002518271',
    brandLogo: '16-04-logo.jpg',
    productDescription:
      'The Visionix VX 120+ Dry Eye is a multi-diagnostic wavefront analyzer featuring Scheimpflug pachymetry, Shack-Hartmann aberrometry, Placido ring corneal topography, and an advanced dry eye screening module.',
    distributorEmail: 'jtawil@amicogroup.com',
  },
  {
    brandName: 'NEBIM ',
    categoryCode: '16',
    categoryName: 'VP-16 Best Value Enhancer (Retail)',
    distributor: 'V-Global',
    productName: 'NEBIM V3 ',
    productCode: '16-03',
    productImage: '16-03.jpg',
    pdf: '16-03.pdf',
    price: '',
    ID: '4012435000002518267',
    brandLogo: '16-03-logo.jpg',
    productDescription:
      'ERP and merchandising software for wholesalers and retailers identify and report the product cards of sunglasses / Optical frames, optical glass, lenses and other product groups specific to the optical industry by identifying them with specific optical product features within Nebim V3.',
    distributorEmail: 'serhat@verimsoft.com',
  },
  {
    brandName: 'GLASKLAR',
    categoryCode: '16',
    categoryName: 'VP-16 Best Value Enhancer (Retail)',
    distributor: 'GLASKLAR International B.V.',
    productName: 'GLASKLAR OPTICA AND GK24K',
    productCode: '16-02',
    productImage: '16-02.jpg',
    pdf: '',
    price: '',
    ID: '4012435000002518263',
    brandLogo: '16-02-logo.jpg',
    productDescription:
      'GLASKLAR OPTICA is the cleaning and care spray for all types of glasses and lens materials. In combination with the refill station, it becomes a sustainable customer loyalty system that leads to increased visit frequency.',
    distributorEmail: 'Gerard.Daudey@glasklar.com',
  },
  {
    brandName: 'ESSILOR INSTRUMENTS ',
    categoryCode: '16',
    categoryName: 'VP-16 Best Value Enhancer (Retail)',
    distributor: 'EssilorLuxottica',
    productName: 'ESTM 800',
    productCode: '16-01',
    productImage: '16-01.jpg',
    pdf: '',
    price: '',
    ID: '4012435000002518259',
    brandLogo: '16-01-logo.jpg',
    productDescription:
      'The ESTM 800 is a compact, user-friendly in-office edging system that showcases craftsmanship and creativity. It includes the ESTM 800 edger and TCBTM 800, an automatic tracer-centered blocker, enhancing ECP work.',
    distributorEmail: 'sameera.khan@ae.luxottica.com',
  },
  {
    brandName: 'VIVALOOK ',
    categoryCode: '15',
    categoryName: 'VP-15 Most Popular Coloured Contact Lens',
    distributor: 'Pharabi Ab',
    productName: 'VIVALOOK',
    productCode: '15-03',
    productImage: '15-03.jpg',
    pdf: '',
    price: '',
    ID: '4012435000002518255',
    brandLogo: '15-03-logo.jpg',
    productDescription: '1-day color lenses\n',
    distributorEmail: 'Sabah.alfalahi@gmail.com',
  },
  {
    brandName: 'SAMA CONTACT LENSES ',
    categoryCode: '15',
    categoryName: 'VP-15 Most Popular Coloured Contact Lens',
    distributor: 'Al Nadharah Cosmetics \u0026 Medical Equipment Trading',
    productName: 'SAMA CONTACT LENSES TORIC',
    productCode: '15-02',
    productImage: '15-02.jpg',
    pdf: '',
    price: '',
    ID: '4012435000002518251',
    brandLogo: '15-02-logo.jpg',
    productDescription:
      'We are the first colored contact lenses for astigmatism in the Middle East.\n',
    distributorEmail: 'ammar@alnadharah.ae',
  },
  {
    brandName: 'ACUVUE ',
    categoryCode: '15',
    categoryName: 'VP-15 Most Popular Coloured Contact Lens',
    distributor: 'Johnson \u0026 Johnson',
    productName: '1-DAY ACUVUE® DEFINE®',
    productCode: '15-01',
    productImage: '15-01.jpg',
    pdf: '',
    price: '',
    ID: '4012435000002518247',
    brandLogo: '15-01-logo.jpg',
    productDescription:
      'Daily Disposable Colored Contact Lenses Inspired by Nature, New Colors: Fresh Lilac \u0026 Fresh Latte\n',
    distributorEmail: 'aalharka@its.jnj.com',
  },
  {
    brandName: 'FACELOOX ',
    categoryCode: '14',
    categoryName: 'VP-14 Most Popular Oph. Contact Lens',
    distributor: 'Pharabi Ab',
    productName: 'CLEAR + MPC ',
    productCode: '14-03',
    productImage: '14-03.jpg',
    pdf: '',
    price: '',
    ID: '4012435000002518243',
    brandLogo: '14-03-logo.jpg',
    productDescription: 'Faceloox clear with MPC, very comfortable and with good price\n',
    distributorEmail: 'Sabah.alfalahi@gmail.com',
  },
  {
    brandName: 'ACUVUE',
    categoryCode: '14',
    categoryName: 'VP-14 Most Popular Oph. Contact Lens',
    distributor: 'Johnson \u0026 Johnson',
    productName: 'OASYS MAX 1-DAY MULTIFOCAL ',
    productCode: '14-02',
    productImage: '14-02.jpg',
    pdf: '',
    price: '',
    ID: '4012435000002518239',
    brandLogo: '14-02-logo.jpg',
    productDescription:
      '1st Daily Disposable Contact Lens from ACUVUE with a blue-light filter. Pupil optimised design for crisp, clear vision both near and far\n',
    distributorEmail: 'ajha10@its.jnj.com',
  },
  {
    brandName: 'ACUVUE ',
    categoryCode: '14',
    categoryName: 'VP-14 Most Popular Oph. Contact Lens',
    distributor: 'Johnson \u0026 Johnson',
    productName: 'OASYS MAX 1-DAY',
    productCode: '14-01',
    productImage: '14-01.jpg',
    pdf: '',
    price: '',
    ID: '4012435000002518235',
    brandLogo: '14-01-logo.jpg',
    productDescription:
      'The 1st daily disposable contact lenses from ACUVUE with a blue light filter.\n',
    distributorEmail: 'ajha10@its.jnj.com',
  },
  {
    brandName: 'ZEISS ',
    categoryCode: '13',
    categoryName: 'VP-13 Most Popular Lens Coating/Value Add',
    distributor: 'ZEISS Vision Care Middle East \u0026 Africa',
    productName: 'PHOTOFUSION X BLACK',
    productCode: '13-04',
    productImage: '13-04.jpg',
    pdf: '',
    price: '',
    ID: '4012435000002518231',
    brandLogo: '13-04-logo.jpg',
    productDescription:
      'ZEISS PhotoFusion X lenses feature fast-reacting photochromic dyes and a speed-optimised matrix, transitioning from clear to dark in seconds. Now available in Black for hot climates, offering rapid adaptability to light changes.',
    distributorEmail: 'marketing.vision.ae@zeiss.com',
  },
  {
    brandName: 'NOVA EYEWEAR ',
    categoryCode: '13',
    categoryName: 'VP-13 Most Popular Lens Coating/Value Add',
    distributor: 'Vision Rx Lab LLC',
    productName: 'SATIN AZURE UV',
    productCode: '13-03',
    productImage: '13-03.jpg',
    pdf: '13-03.pdf',
    price: '',
    ID: '4012435000002518227',
    brandLogo: '13-03-logo.jpg',
    productDescription:
      'Satin Azure UV is an advanced anti-reflective coating for millennials, enhancing lens appearance by minimizing reflections and ensuring clear, sharp vision throughout the day.',
    distributorEmail: 'kumarsiv@vrxlab.com',
  },
  {
    brandName: 'HOYA',
    categoryCode: '13',
    categoryName: 'VP-13 Most Popular Lens Coating/Value Add',
    distributor: 'Yateem Vision',
    productName: 'HI-VISION MEIRYO',
    productCode: '13-02',
    productImage: '13-02.jpg',
    pdf: '',
    price: '',
    ID: '4012435000002518223',
    brandLogo: '13-02-logo.jpg',
    productDescription:
      'Hi-Vision Meiryo, HOYA\u0027s latest premium coating, offers exceptional clarity, superior scratch resistance, and robust UV protection. It’s ideal for those who prioritize long-lasting quality and daily durability, enhancing overall satisfaction.',
    distributorEmail: 'mathews@yateemgroup.ae',
  },
  {
    brandName: 'CRIZAL-ESSILORLUXOTTICA',
    categoryCode: '13',
    categoryName: 'VP-13 Most Popular Lens Coating/Value Add',
    distributor: 'EssilorLuxottica Middle East',
    productName: 'CRIZAL® SAPPHIRETM HR',
    productCode: '13-01',
    productImage: '13-01.jpg',
    pdf: '',
    price: '',
    ID: '4012435000002518219',
    brandLogo: '13-01-logo.jpg',
    productDescription:
      'Featuring High Surface Density Process Technology™, High-Resistance Technology™ for durability, Multi-Angular Technology™ for transparency, and SPF 35 protection, this anti-reflective coating is rated the best overall based on 2020 external and internal tests.',
    distributorEmail: 'sameera.khan@ae.luxottica.com',
  },
  {
    brandName: 'ZEISS',
    categoryCode: '12',
    categoryName: 'VP-12 Most Popular Lens (Best Value)',
    distributor: 'ZEISS Vision Care Middle East \u0026 Africa',
    productName: 'MYOCARE FINISHED SINGLE VISION',
    productCode: '12-03',
    productImage: '12-03.jpg',
    pdf: '',
    price: '',
    ID: '4012435000002518215',
    brandLogo: '12-03-logo.jpg',
    productDescription:
      'ZEISS introduces its first age-related myopia management stock lens, designed to balance clear vision with effective progressive myopia management for children.',
    distributorEmail: 'marketing.vision.ae@zeiss.com',
  },
  {
    brandName: 'PRIME',
    categoryCode: '12',
    categoryName: 'VP-12 Most Popular Lens (Best Value)',
    distributor: 'GKB Ophthalmics Products FZE',
    productName: 'Q-SHADE+',
    productCode: '12-02',
    productImage: '12-02.jpg',
    pdf: '12-02.pdf',
    price: '',
    ID: '4012435000002518211',
    brandLogo: '12-02-logo.jpg',
    productDescription:
      'PRIME Rx lenses feature Clear \u0026 advanced Photochromic Q-Shade+ technology. Available as Q-Shade+ Single Vision Digital, Q-Shade+ Progressive Digital, and Myo SHARP for children. Coatings include HC, HMC, SH-HMC, Blue Protect, Night Vision, and Mirror.',
    distributorEmail: 'domnic.mendes@gkbvision.com',
  },
  {
    brandName: 'EYEZEN® - ESSILORLUXOTTICA',
    categoryCode: '12',
    categoryName: 'VP-12 Most Popular Lens (Best Value)',
    distributor: 'EssilorLuxottica',
    productName: 'EYEZEN® - ESSILORLUXOTTICA',
    productCode: '12-01',
    productImage: '12-01.jpg',
    pdf: '',
    price: '',
    ID: '4012435000002518207',
    brandLogo: '12-01-logo.jpg',
    productDescription:
      'Eyezen® Boost lenses, for single-vision wearers with visual fatigue, feature power distribution technology to optimize object distance and gaze direction, while supporting accommodative effort for near vision with varying addition powers.',
    distributorEmail: 'sameera.khan@ae.luxottica.com',
  },
  {
    brandName: 'ZEISS',
    categoryCode: '11',
    categoryName: 'VP-11 Most Popular Lens (Progressive)',
    distributor: 'ZEISS Vision Care Middle East \u0026 Africa',
    productName: 'PROGRESSIVE SMARTLIFE INDIVIDUAL 3',
    productCode: '11-04',
    productImage: '11-04.jpg',
    pdf: '',
    price: '',
    ID: '4012435000002518203',
    brandLogo: '11-04-logo.jpg',
    productDescription:
      'ZEISS Progressive SmartLife Individual 3 lenses, with ZEISS Intelligence Augmented Design™ Technology, ensure smooth vision from near to far and quick adaptation, tailored to the wearer’s personal visual needs for complete freedom of vision.',
    distributorEmail: 'marketing.vision.ae@zeiss.com',
  },
  {
    brandName: 'VARILUX® – ESSILORLUXOTTICA ',
    categoryCode: '11',
    categoryName: 'VP-11 Most Popular Lens (Progressive)',
    distributor: 'EssilorLuxottica',
    productName: 'VARILUX® XR SERIESTM​',
    productCode: '11-03',
    productImage: '11-03.jpg',
    pdf: '',
    price: '',
    ID: '4012435000002518199',
    brandLogo: '11-03-logo.jpg',
    productDescription:
      'Developed with AI and 1 million data points, these progressive lenses predict visual behavior to offer instant sharpness and seamless transitions from near to far.',
    distributorEmail: 'sameera.khan@ae.luxottica.com',
  },
  {
    brandName: 'NOVA EYEWEAR ',
    categoryCode: '11',
    categoryName: 'VP-11 Most Popular Lens (Progressive)',
    distributor: 'Vision Rx Lab LLC',
    productName: 'NOVA AI',
    productCode: '11-02',
    productImage: '11-02.jpg',
    pdf: '11-02.pdf',
    price: '',
    ID: '4012435000002518195',
    brandLogo: '11-02-logo.jpg',
    productDescription:
      'Nova Ai progressive lenses use advanced AI-powered optical technology to offer unmatched personalization, tailoring vision care to each wearer’s unique perspective for superior clarity and comfort.',
    distributorEmail: 'kumarsiv@vrxlab.com',
  },
  {
    brandName: 'HOYA',
    categoryCode: '11',
    categoryName: 'VP-11 Most Popular Lens (Progressive)',
    distributor: 'Yateem Vision',
    productName: 'HOYALUX ID MYSELF',
    productCode: '11-01',
    productImage: '11-01.jpg',
    pdf: '',
    price: '',
    ID: '4012435000002518191',
    brandLogo: '11-01-logo.jpg',
    productDescription:
      'Hoyalux iD MySelf, HOYA\u0027s top progressive lens, offers customised clear vision at all distances with AdaptEase Technology. It enhances near and intermediate fields and smooth transitions, perfect for digital and outdoor use.',
    distributorEmail: 'mathews@yateemgroup.ae',
  },
  {
    brandName: 'VERY FRENCH GANGSTERS ',
    categoryCode: '10',
    categoryName: 'VP-10 Best New Children\u0027s Eyewear',
    distributor: 'Sas Wanted',
    productName: 'VERY STAR ',
    productCode: '10-05',
    productImage: '10-05.jpg',
    pdf: '',
    price: '',
    ID: '4012435000002518187',
    brandLogo: '10-05-logo.jpg',
    productDescription:
      'Frame for girls in colour nude glitter 100 % handmade in France in a personalised Italian mazzuchelli acetate.',
    distributorEmail: 'christine@veryfrenchgangsters.com',
  },
  {
    brandName: 'RAY-BAN',
    categoryCode: '10',
    categoryName: 'VP-10 Best New Children\u0027s Eyewear',
    distributor: 'EssilorLuxottica',
    productName: 'X MARVEL SPIDERMAN CAPSULE-0RJ6069S',
    productCode: '10-04',
    productImage: '10-04.jpg',
    pdf: '',
    price: '',
    ID: '4012435000002518183',
    brandLogo: '10-04-logo.jpg',
    productDescription:
      'Junior Justin, from the Ray-Ban | MARVEL collab, is inspired by MARVEL’s Spider-Verse. Available for girls and boys, these iconic styles feature supercharged color combinations to match kids\u0027 superpowers.',
    distributorEmail: 'sameera.khan@ae.luxottica.com',
  },
  {
    brandName: 'POLAROID',
    categoryCode: '10',
    categoryName: 'VP-10 Best New Children\u0027s Eyewear',
    distributor: 'Safilo Middle East FZCO',
    productName: 'PLD GUMMY 8049/S',
    productCode: '10-03',
    productImage: '10-03.jpg',
    pdf: '',
    price: '',
    ID: '4012435000002518179',
    brandLogo: '10-03-logo.jpg',
    productDescription:
      'In a two-tone palette, these bold square frames feature protective Polaroid lenses and a comfortable rubber structure, perfect for outdoor activities. Pops of color on the lugs and end tips add a touch of originality.',
    distributorEmail: 'paolakanta@safilo.com',
  },
  {
    brandName: 'MIRAFLEX ',
    categoryCode: '10',
    categoryName: 'VP-10 Best New Children\u0027s Eyewear',
    distributor: 'EssilorLuxottica',
    productName: 'MF4008',
    productCode: '10-02',
    productImage: '10-02.jpg',
    pdf: '',
    price: '',
    ID: '4012435000002518175',
    brandLogo: '10-02-logo.jpg',
    productDescription: 'For kids and infants, safe and flexible.\n',
    distributorEmail: 'sameera.khan@ae.luxottica.com',
  },
  {
    brandName: 'IDEE EYEWEAR ',
    categoryCode: '10',
    categoryName: 'VP-10 Best New Children\u0027s Eyewear',
    distributor: 'FourPlus General Trading LLC',
    productName: 'SY 610',
    productCode: '10-01',
    productImage: '10-01.jpg',
    pdf: '',
    price: '',
    ID: '4012435000002518171',
    brandLogo: '10-01-logo.jpg',
    productDescription:
      'IDEE\u0027s kids\u0027 sunglasses collection combines style and functionality. Designed to match young tastes, each pair is crafted with precision for ultimate comfort.',
    distributorEmail: 'marketingdxb@fourplusmedia.com',
  },
  {
    brandName: 'OUT OF ',
    categoryCode: '09',
    categoryName: 'VP-09 Best New Sports Eyewear',
    distributor: 'Out Of Srl',
    productName: 'ACUITY',
    productCode: '09-05',
    productImage: '09-05.jpg',
    pdf: '',
    price: '',
    ID: '4012435000002518167',
    brandLogo: '09-05-logo.jpg',
    productDescription:
      'Acuity sunglasses feature an integrated prescription shield, eliminating the issues of traditional ophthalmic clip-ins, which often cover limited visual defects and can accumulate dirt due to gaps between lenses.',
    distributorEmail: 'riccardo.comaron@out-of.com',
  },
  {
    brandName: 'OAKLEY ',
    categoryCode: '09',
    categoryName: 'VP-09 Best New Sports Eyewear',
    distributor: 'EssilorLuxottica Middle East',
    productName: 'SPHAERA',
    productCode: '09-04',
    productImage: '09-04.jpg',
    pdf: '',
    price: '',
    ID: '4012435000002518163',
    brandLogo: '09-04-logo.jpg',
    productDescription:
      'Sphaera’s wide field of view, combined with Prizm™ Lens Technology, ensures detailed vision. The lightweight O Matter™ frame fits comfortably with hats and helmets and includes a front vent for enhanced airflow.',
    distributorEmail: 'sameera.khan@ae.luxottica.com',
  },
  {
    brandName: 'IDEE EYEWEAR ',
    categoryCode: '09',
    categoryName: 'VP-09 Best New Sports Eyewear',
    distributor: 'FourPlus General Trading LLC',
    productName: 'S3077',
    productCode: '09-03',
    productImage: '09-03.jpg',
    pdf: '',
    price: '',
    ID: '4012435000002518159',
    brandLogo: '09-03-logo.jpg',
    productDescription:
      'IDEE Eyewear, a leading brand in India for over two decades, features Bollywood stars like Alia Bhatt, Varun Dhawan, and Siddhant Chaturvedi. Known for blending top-notch designs with competitive pricing, IDEE offers exceptional value. The IDEE S3120 model exemplifies this with unmatched elegance and quality.',
    distributorEmail: 'marketingdxb@fourplusmedia.com',
  },
  {
    brandName: 'DEMETZ',
    categoryCode: '09',
    categoryName: 'VP-09 Best New Sports Eyewear',
    distributor: 'Future Optics Co. L.L.C',
    productName: 'SMASH 517857',
    productCode: '09-02',
    productImage: '09-02.jpg',
    pdf: '',
    price: '',
    ID: '4012435000002518155',
    brandLogo: '09-02-logo.jpg',
    productDescription:
      'Ideal for sports like football, basketball, and tennis, this bi-material frame combines eco-friendly G850, derived from castor beans, with rubber. It features shock protection on the bridge and temples, anti-fog ventilation, a double-loop adjustable strap, and a pouch case.',
    distributorEmail: 'banito@eim.ae',
  },
  {
    brandName: 'DEMETZ ',
    categoryCode: '09',
    categoryName: 'VP-09 Best New Sports Eyewear',
    distributor: 'Opal',
    productName: 'FLOAT-D',
    productCode: '09-01',
    productImage: '09-01.jpg',
    pdf: '',
    price: '',
    ID: '4012435000002518151',
    brandLogo: '09-01-logo.jpg',
    productDescription:
      'The floating innovation features a 100% biobased CX1363 material, blending strength, comfort, and lightness. The encapsulated air system in the temples ensures buoyancy, preventing lost goggles during aquatic activities.',
    distributorEmail: 'jclavel@opal.fr',
  },
  {
    brandName: 'TBD EYEWEAR',
    categoryCode: '08',
    categoryName: 'VP-08 Best New Sunglass Model (Popular)',
    distributor: 'Vision Eye Wear FZE',
    productName: 'CORD ECO BLACK | ORANGE',
    productCode: '08-05',
    productImage: '08-05.jpg',
    pdf: '',
    price: '',
    ID: '4012435000002518147',
    brandLogo: '08-05-logo.jpg',
    productDescription:
      'The CORD model from TBD Eyewear, like all their frames, is made from sustainable bio-acetate. It features bio-nylon sun lenses, biodegradable demo lenses, and packaging with recycled cotton and PET plastic. The design includes robust squared frames with vintage-style temples.',
    distributorEmail: 'marco.bonapace@gmail.com',
  },
  {
    brandName: 'RAY BAN',
    categoryCode: '08',
    categoryName: 'VP-08 Best New Sunglass Model (Popular)',
    distributor: 'EssilorLuxottica',
    productName: 'AVIATOR REVERSE RBR0101S',
    productCode: '08-04',
    productImage: '08-04.jpg',
    pdf: '',
    price: '',
    ID: '4012435000002518143',
    brandLogo: '08-04-logo.jpg',
    productDescription:
      'The exclusive gunmetal colour of Aviator Reverse (innovative concave lens) matches with a unique gunmetal chain. Customized Packaging with Arabic Logomania.',
    distributorEmail: 'sameera.khan@ae.luxottica.com',
  },
  {
    brandName: 'POLICE',
    categoryCode: '08',
    categoryName: 'VP-08 Best New Sunglass Model (Popular)',
    distributor: 'De Rigo Vision Middle east FZCO',
    productName: 'SPLN42M',
    productCode: '08-03',
    productImage: '08-03.jpg',
    pdf: '',
    price: '',
    ID: '4012435000002518139',
    brandLogo: '08-03-logo.jpg',
    productDescription:
      'Aeko sunglasses with PolarizedPlus2® technology enhance detail focus for your adventures. Featuring a thin, refined titanium frame, geometric lenses with shiny edges, and meticulous attention to detail. Horizon temple design includes integrated flex and transparent tips.',
    distributorEmail: 'mukil.somadas@derigome.com',
  },
  {
    brandName: 'IDEE EYEWEAR ',
    categoryCode: '08',
    categoryName: 'VP-08 Best New Sunglass Model (Popular)',
    distributor: 'FourPlus General Trading LLC',
    productName: 'S3120',
    productCode: '08-02',
    productImage: '08-02.jpg',
    pdf: '',
    price: '',
    ID: '4012435000002518135',
    brandLogo: '08-02-logo.jpg',
    productDescription:
      'IDEE Eyewear, India’s top brand for over two decades, features Bollywood stars like Alia Bhatt, Varun Dhawan, and Siddhant Chaturvedi. Known for blending top-notch design with competitive pricing, IDEE offers exceptional value. The IDEE S3120 model exemplifies elegance in design and quality at an unmatched price.',
    distributorEmail: 'marketingdxb@fourplusmedia.com',
  },
  {
    brandName: 'CARRERA ',
    categoryCode: '08',
    categoryName: 'VP-08 Best New Sunglass Model (Popular)',
    distributor: 'Safilo Middle East FZCO',
    productName: 'VICTORY ',
    productCode: '08-01',
    productImage: '08-01.jpg',
    pdf: '',
    price: '',
    ID: '4012435000002518131',
    brandLogo: '08-01-logo.jpg',
    productDescription:
      'Carrera continues to lead in eyewear with its Italian-made navigator front. Featuring the brand\u0027s \u0027C\u0027 detail and a horizontal Carrera flag on the double bridge, it celebrates the Victory logo. Blue-blocking lenses protect eyes from screen light.',
    distributorEmail: 'paolakanta@safilo.com',
  },
  {
    brandName: 'SILHOUETTE',
    categoryCode: '07',
    categoryName: 'VP-07 Best New Sunglass Model (Niche)',
    distributor: 'Silhouette International',
    productName: 'TITAN MINIMAL ART COSMIC 9913 ',
    productCode: '07-05',
    productImage: '07-05.jpg',
    pdf: '',
    price: '',
    ID: '4012435000002518127',
    brandLogo: '07-05-logo.jpg',
    productDescription:
      'Silhouette’s iconic collection introduces its boldest member yet: a futuristic sun shade merging digital age aesthetics with 1960s and 70s retro glamour. This edgy, galactic design is set to become a street-style icon and runway statement piece.',
    distributorEmail: 'e.capaldi@ae.silhouette.com',
  },
  {
    brandName: 'OUT OF ',
    categoryCode: '07',
    categoryName: 'VP-07 Best New Sunglass Model (Niche)',
    distributor: 'Out Of Srl',
    productName: 'V-1',
    productCode: '07-04',
    productImage: '07-04.jpg',
    pdf: '',
    price: '',
    ID: '4012435000002518123',
    brandLogo: '07-04-logo.jpg',
    productDescription:
      'V-1 sunglasses with IRID X-10 technology adapt from 60% to 6% transmittance almost instantly, offering ultimate visual comfort in any condition, including inside cars. Battery-free and eco-friendly, they adjust to light changes in under 1 second, ideal for 24/7 use.',
    distributorEmail: 'riccardo.comaron@out-of.com',
  },
  {
    brandName: 'OLIVER PEOPLES ',
    categoryCode: '07',
    categoryName: 'VP-07 Best New Sunglass Model (Niche)',
    distributor: 'EssilorLuxottica',
    productName: '0OV5183S DUSTY AQUA',
    productCode: '07-03',
    productImage: '07-03.jpg',
    pdf: '',
    price: '',
    ID: '4012435000002518119',
    brandLogo: '07-03-logo.jpg',
    productDescription:
      'Oliver Peoples sunglasses for men model OV5183S featuring dusty aqua full rim acetate frame and base 6 glass cognac lens.',
    distributorEmail: 'sameera.khan@ae.luxottica.com',
  },
  {
    brandName: 'NATHALIE FORDEYN ',
    categoryCode: '07',
    categoryName: 'VP-07 Best New Sunglass Model (Niche)',
    distributor: 'Nathalie Fordeyn Eyewear',
    productName: 'LINEN COLOR TORTOISE',
    productCode: '07-02',
    productImage: '07-02.jpg',
    pdf: '',
    price: '',
    ID: '4012435000002518115',
    brandLogo: '07-02-logo.jpg',
    productDescription:
      'The "Linen" sunglasses are lightweight and slightly oversized, featuring an iconic metallic accent in Japanese gold. The frame showcases a refined production technique with a combination of round and ellipse shapes for elegant sophistication.',
    distributorEmail: 'hello@nathaliefordeyneyewear.com',
  },
  {
    brandName: 'L.G.R',
    categoryCode: '07',
    categoryName: 'VP-07 Best New Sunglass Model (Niche)',
    distributor: 'Vision Eye Wear FZE',
    productName: 'ZANZIBAR',
    productCode: '07-01',
    productImage: '07-01.jpg',
    pdf: '',
    price: '',
    ID: '4012435000002518111',
    brandLogo: '07-01-logo.jpg',
    productDescription:
      'The Zanzibar sunglasses showcase slender, minimalist lines for a striking look. Handmade in Italy from polished cellulose acetate, they feature hexagonal photochromic lenses that offer 100% UV protection, and anti-reflective, oleophobic, hydrophobic, and anti-scratch properties.',
    distributorEmail: 'marco.bonapace@gmail.com',
  },
  {
    brandName: 'ROBERTO CAVALLI ',
    categoryCode: '06',
    categoryName: 'VP-06 Best New Sunglass Model (Designer)',
    distributor: 'De Rigo Vision Middle east FZCO.',
    productName: 'SRC032M',
    productCode: '06-03',
    productImage: '06-03.jpg',
    pdf: '',
    price: '',
    ID: '4012435000002518107',
    brandLogo: '06-03-logo.jpg',
    productDescription:
      'Pilot shape of the front, which construction leaves an empty space between the rim and the lenses. The external profile of the rim is engraved to reproduce the skin of the snake effect.',
    distributorEmail: 'mukil.somadas@derigome.com',
  },
  {
    brandName: 'CAROLINA HERRERA',
    categoryCode: '06',
    categoryName: 'VP-06 Best New Sunglass Model (Designer)',
    distributor: 'Safilo Middle East FZCO',
    productName: 'HER 0215/S ',
    productCode: '06-02',
    productImage: '06-02.jpg',
    pdf: '',
    price: '',
    ID: '4012435000002518103',
    brandLogo: '06-02-logo.jpg',
    productDescription:
      'Carolina Herrera\u0027s Diva sunglasses showcase the brand’s signature style with glossy acetate and a sculptural, three-dimensional finish. Bold oversized temples and precious floral embellishments make these sunglasses a standout statement piece.',
    distributorEmail: 'paolakanta@safilo.com',
  },
  {
    brandName: 'BOSS',
    categoryCode: '06',
    categoryName: 'VP-06 Best New Sunglass Model (Designer)',
    distributor: 'Safilo Middle East FZCO',
    productName: 'BOSS 1625/S ',
    productCode: '06-01',
    productImage: '06-01.jpg',
    pdf: '',
    price: '',
    ID: '4012435000002518099',
    brandLogo: '06-01-logo.jpg',
    productDescription:
      'These sunglasses feature vintage-inspired frames, multifaceted surfaces, and masculine colors. The metal insert, sculpted into the BOSS three stripes and wrapping from front to temples, adds character. Made from eco-acetate.',
    distributorEmail: 'paolakanta@safilo.com',
  },
  {
    brandName: 'OVVO OPTICS',
    categoryCode: '05',
    categoryName: 'VP-05 Best New Sunglass Model (Luxury)',
    distributor: 'OVVO Optics',
    productName: 'JACK -14KT',
    productCode: '05-05',
    productImage: '05-05.jpg',
    pdf: '',
    price: '',
    ID: '4012435000002518095',
    brandLogo: '05-05-logo.jpg',
    productDescription:
      'Style Jack (6035) features a 14kt gold-plated frame core made from OVVO’s proprietary surgical steel and titanium composite. It includes OVVO\u0027s patented screwless hinge for flexible, lightweight, and comfortable everyday wear.',
    distributorEmail: 'margareta.augustyn@ovvooptics.com',
  },
  {
    brandName: 'MOVITRA',
    categoryCode: '05',
    categoryName: 'VP-05 Best New Sunglass Model (Luxury)',
    distributor: 'Vision Eye Wear FZE',
    productName: 'TN 01 B',
    productCode: '05-04',
    productImage: '05-04.jpg',
    pdf: '',
    price: '',
    ID: '4012435000002518091',
    brandLogo: '05-04-logo.jpg',
    productDescription:
      'The Limited Edition TN 01 B boasts CNC titanium rims, snouts, and a flex case with a brushed finish. It features a 4mm raised rim for lens protection and an Innovative Patented Closing System® for rotation and pocketability.',
    distributorEmail: 'anna@visioneyewear.ae',
  },
  {
    brandName: 'MAYBACH ',
    categoryCode: '05',
    categoryName: 'VP-05 Best New Sunglass Model (Luxury)',
    distributor: 'Yateem Vision',
    productName: 'THE KING III',
    productCode: '05-03',
    productImage: '05-03.jpg',
    pdf: '',
    price: '',
    ID: '4012435000002518087',
    brandLogo: '05-03-logo.jpg',
    productDescription:
      'THE KING III sunglasses blend 18K gold-plated frames with laminated exotic burlwood temples in piano lacquer. A luxurious fusion of opulence and artistry, each pair exudes sophistication and timeless style.',
    distributorEmail: 'yatvision@yacodxb.ae',
  },
  {
    brandName: 'CHOPARD',
    categoryCode: '05',
    categoryName: 'VP-05 Best New Sunglass Model (Luxury)',
    distributor: 'De Rigo Vision Middle east FZCO',
    productName: 'SCHL30S',
    productCode: '05-02',
    productImage: '05-02.jpg',
    pdf: '',
    price: '',
    ID: '4012435000002518083',
    brandLogo: '05-02-logo.jpg',
    productDescription:
      'Inspired by the world’s most glamorous. Red Carpet: this Limited-Edition style, made in 23 kt gold metal plated, features a soft cat eye rimless front with a precious floral decoration of 140 top-quality zircons.',
    distributorEmail: 'mukil.somadas@derigome.com',
  },
  {
    brandName: 'ANNA-KARIN KARLSSON',
    categoryCode: '05',
    categoryName: 'VP-05 Best New Sunglass Model (Luxury)',
    distributor: 'Anna-Karin Karlsson Exclusive AB',
    productName: 'CRYSTAL MERINGUE',
    productCode: '05-01',
    productImage: '05-01.jpg',
    pdf: '',
    price: '',
    ID: '4012435000002518079',
    brandLogo: '05-01-logo.jpg',
    productDescription:
      'A poetic ode to meringue\u0027s sweetness and monumental architecture blended with Middle Eastern fairytales. The swirly cut lenses embody the essence of beauty in intricacies, celebrating the delicate and grand, intricate and mesmerising.',
    distributorEmail: 'fanny@annakarinkarlsson.com',
  },
  {
    brandName: 'STEPPER',
    categoryCode: '04',
    categoryName: 'VP-04 Best New Spectacle Frame (Popular)',
    distributor: 'Future Optics Co. L.L.C',
    productName: 'SI- 60229',
    productCode: '04-05',
    productImage: '04-05.jpg',
    pdf: '',
    price: '',
    ID: '4012435000002518075',
    brandLogo: '04-05-logo.jpg',
    productDescription:
      'This hypoallergenic, ultra-light titanium frame weighs just 12.70 grams. Made by a German brand with materials imported from Japan, it ensures optimal comfort and fit. Each frame includes a hard case, a paper bag, and a microfiber cleaning cloth.',
    distributorEmail: 'banito@eim.ae',
  },
  {
    brandName: 'RAY-BAN',
    categoryCode: '04',
    categoryName: 'VP-04 Best New Spectacle Frame (Popular)',
    distributor: 'EssilorLuxottica',
    productName: 'WAYFARER CHANGE RB 2140',
    productCode: '04-04',
    productImage: '04-04.jpg',
    pdf: '',
    price: '',
    ID: '4012435000002518071',
    brandLogo: '04-04-logo.jpg',
    productDescription:
      'Ray-Ban’s first light-responsive frame, powered by Transitions®, features new patented acetate technology that changes colour with light.',
    distributorEmail: 'sameera.khan@ae.luxottica.com',
  },
  {
    brandName: 'POLICE',
    categoryCode: '04',
    categoryName: 'VP-04 Best New Spectacle Frame (Popular)',
    distributor: 'De Rigo Vision Middle East FZCO',
    productName: 'VPLN27M',
    productCode: '04-03',
    productImage: '04-03.jpg',
    pdf: '',
    price: '',
    ID: '4012435000002518067',
    brandLogo: '04-03-logo.jpg',
    productDescription: 'Horizon temple design, with integrated flex and transparent temple tips.',
    distributorEmail: 'mukil.somadas@derigome.com',
  },
  {
    brandName: 'DAMAS EYEWEAR',
    categoryCode: '04',
    categoryName: 'VP-04 Best New Spectacle Frame (Popular)',
    distributor: 'Vision Trade Srl',
    productName: 'DMS 1331',
    productCode: '04-02',
    productImage: '04-02.jpg',
    pdf: '',
    price: '',
    ID: '4012435000002518063',
    brandLogo: '04-02-logo.jpg',
    productDescription:
      'Damas Eyewear combines Italian craftsmanship with innovation. The DMS 1331 C190, made from Beta Titanium, features a lightweight, durable frame with detailed front and titanium drop-shaped nose pads for exceptional comfort and elegance.',
    distributorEmail: 'cristina@visiontrade.it',
  },
  {
    brandName: 'CARRERA',
    categoryCode: '04',
    categoryName: 'VP-04 Best New Spectacle Frame (Popular)',
    distributor: 'Safilo Middle East FZCO',
    productName: 'VICTORY',
    productCode: '04-01',
    productImage: '04-01.jpg',
    pdf: '',
    price: '',
    ID: '4012435000002518059',
    brandLogo: '04-01-logo.jpg',
    productDescription:
      'Carrera eyeglasses, crafted in Italy, feature meticulous detail and quality. With a distinctive plaque of the brand’s flag and logo on the temples, they offer a unique and recognisable style.',
    distributorEmail: 'paolakanta@safilo.com',
  },
  {
    brandName: 'TECH PRINT INDUSTRIES',
    categoryCode: '03',
    categoryName: 'VP-03 Best New Spectacle Frame (Niche)',
    distributor: 'Tech Print Industries',
    productName: 'TPI RIBBON FRAME',
    productCode: '03-04',
    productImage: '03-04.jpg',
    pdf: '',
    price: '',
    ID: '4012435000002518055',
    brandLogo: '03-04-logo.jpg',
    productDescription:
      'TPI\u0027s 3D-printed Ribbon Frame redefines eyewear with advanced technology, combining comfort, style, and precision. It recycles materials to reduce waste, resulting in a lightweight, strong, and eco-friendly frame that sets a new standard.',
    distributorEmail: 'kiana@techprintindustries.com',
  },
  {
    brandName: 'SILHOUETTE',
    categoryCode: '03',
    categoryName: 'VP-03 Best New Spectacle Frame (Niche)',
    distributor: 'Silhouette International',
    productName: 'DYNAMIC DAWN 4566',
    productCode: '03-03',
    productImage: '03-03.jpg',
    pdf: '',
    price: '',
    ID: '4012435000002518051',
    brandLogo: '03-03-logo.jpg',
    productDescription:
      'Silhouette’s Dynamic Dawn design family blends urban style with effortless sophistication. Featuring understated elegance, avant-garde flair, and immaculate front detailing, it offers an ultra-light design for perfect harmony.',
    distributorEmail: 'e.capaldi@ae.silhouette.com',
  },
  {
    brandName: 'ORGREEN OPTICS',
    categoryCode: '03',
    categoryName: 'VP-03 Best New Spectacle Frame (Niche)',
    distributor: 'Orgreen Optics',
    productName: 'URSA MAJOR 3DRING',
    productCode: '03-02',
    productImage: '03-02.jpg',
    pdf: '',
    price: '',
    ID: '4012435000002518047',
    brandLogo: '03-02-logo.jpg',
    productDescription:
      'The Imaginary Lines Collection redefines premium Japanese titanium, showcasing Ørgreen’s metal expertise in its thinnest, most minimalist form. Featuring timeless silhouettes and a 3D-printed ring, it strikes the perfect balance between comfort and design.',
    distributorEmail: 'mattia@orgreenoptics.com',
  },
  {
    brandName: 'ORGREEN OPTICS',
    categoryCode: '03',
    categoryName: 'VP-03 Best New Spectacle Frame (Niche)',
    distributor: 'Orgreen Optics',
    productName: 'QUANTUM HIGH BACKSPACER',
    productCode: '03-01',
    productImage: '03-01.jpg',
    pdf: '',
    price: '',
    ID: '4012435000002518043',
    brandLogo: '03-01-logo.jpg',
    productDescription:
      '3D printing unlocks design freedom, enabling complex shapes and striking structures that were once impossible or cost-prohibitive with traditional techniques. This technology offers new possibilities for creating uniquely functional frames.',
    distributorEmail: 'mattia@orgreenoptics.com',
  },
  {
    brandName: 'FACE A FACE',
    categoryCode: '02',
    categoryName: 'VP-02 Best New Spectacle Frame (Designer)',
    distributor: 'Design Eyewear Group',
    productName: 'EIFFEL 3',
    productCode: '02-03',
    productImage: '02-03.jpg',
    pdf: '',
    price: '',
    ID: '4012435000002518039',
    brandLogo: '02-03-logo.jpg',
    productDescription:
      'The Eiffel model features fine, bright lines against a dark background, echoing the aesthetics of the iconic tower\u0027s metal bars. This playful and poetic design blends lightness with sophistication.',
    distributorEmail: 'cro@designeyeweargroup.com',
  },
  {
    brandName: 'FACE A FACE',
    categoryCode: '02',
    categoryName: 'VP-02 Best New Spectacle Frame (Designer)',
    distributor: 'Design Eyewear Group',
    productName: 'BOCCA KUMA 1',
    productCode: '02-02',
    productImage: '02-02.jpg',
    pdf: '',
    price: '',
    ID: '4012435000002518035',
    brandLogo: '02-02-logo.jpg',
    productDescription:
      'The Bocca Kuma\u0027s design balances severity and lightheartedness with a transverse colour bar, sculpted feminine high arch, and small coloured boots, making it a standout graphic element.',
    distributorEmail: 'cro@designeyeweargroup.com',
  },
  {
    brandName: 'ALBERT I’MSTEIN',
    categoryCode: '02',
    categoryName: 'VP-02 Best New Spectacle Frame (Designer)',
    distributor: 'BizincuLab',
    productName: 'MONTREAL',
    productCode: '02-01',
    productImage: '02-01.jpg',
    pdf: '',
    price: '',
    ID: '4012435000002518031',
    brandLogo: '02-01-logo.jpg',
    productDescription:
      'Montreal, inspired by its namesake city\u0027s free spirit, features a contrasting inner lamination that adds a creative, artistic touch to the classic shape and subdued primary color of the frame.',
    distributorEmail: 'luna.lee@bizinculab.pl',
  },
  {
    brandName: 'KIURV',
    categoryCode: '01',
    categoryName: 'VP-01 Best New Spectacle Frame (Luxury)',
    distributor: 'Eyecon Srl',
    productName: 'FERRY K03',
    productCode: '01-03',
    productImage: '01-03.jpg',
    pdf: '',
    price: '',
    ID: '4012435000002518027',
    brandLogo: '01-03-logo.jpg',
    productDescription:
      'Handcrafted in Italy, this luxury acetate collection features custom 24Kt gold-plated hinges and small parts. Made by skilled artisans, each piece exemplifies the finest in high-quality, handcrafted eyewear.',
    distributorEmail: 'michele.rovere@kiurv.com',
  },
  {
    brandName: 'OVVO OPTICS',
    categoryCode: '01',
    categoryName: 'VP-01 Best New Spectacle Frame (Luxury)',
    distributor: 'Ovvo Optics',
    productName: 'STYLE 3903',
    productCode: '01-05',
    productImage: '01-05.jpg',
    pdf: '',
    price: '',
    ID: '4012435000002518023',
    brandLogo: '01-05-logo.jpg',
    productDescription:
      'This collection combines carbon fibre\u0027s durability with the elegance of exotic wood and 14kt gold. Nine layers of carbon fibre are layered between lightweight wood, creating a strong foundation with rich woodgrain texture and detail.',
    distributorEmail: 'margareta.augustyn@ovvooptics.com',
  },
  {
    brandName: 'ANNA-KARIN KARLSSON',
    categoryCode: '01',
    categoryName: 'VP-01 Best New Spectacle Frame (Luxury)',
    distributor: 'Qima Eyewear',
    productName: 'ROSE ET LE RÊVE',
    productCode: '01-01',
    productImage: '01-01.jpg',
    pdf: '',
    price: '',
    ID: '4012435000002518019',
    brandLogo: '01-01-logo.jpg',
    productDescription:
      'Step into a world where reality meets reverie. The exquisitely crafted panther offers opulence, transforming your vision into a dreamlike experience. You are both the dreamer and the dream.',
    distributorEmail: 'fanny@annakarinkarlsson.com',
  },
  {
    brandName: 'LEIMANN',
    categoryCode: '01',
    categoryName: 'VP-01 Best New Spectacle Frame (Luxury)',
    distributor: 'Leimann',
    productName: 'LEIM VI',
    productCode: '01-04',
    productImage: '01-04.jpg',
    pdf: '',
    price: '',
    ID: '4012435000002518015',
    brandLogo: '01-04-logo.jpg',
    productDescription:
      'The LEIM VI C 003, with its rectangular shape in black and gold, features a vintage bridge and exudes elegance and originality. Handmade in France from a single piece of metal, it’s lightweight yet durable.',
    distributorEmail: 'contact@leimann.fr',
  },
  {
    brandName: 'CHOPARD',
    categoryCode: '01',
    categoryName: 'VP-01 Best New Spectacle Frame (Luxury)',
    distributor: 'De Rigo Vision Middle East FZCO',
    productName: 'VCHG87',
    productCode: '01-02',
    productImage: '01-02.jpg',
    pdf: '',
    price: '',
    ID: '4012435000002518011',
    brandLogo: '01-02-logo.jpg',
    productDescription:
      'Inspired by the Alps and the majestic eagle, this sport-chic eyewear features a contemporary acetate front, 3D metal temples, integrated flex hinge, and refined stainless-steel temple crafted by MIM.',
    distributorEmail: 'mukil.somadas@derigome.com',
  },
]
