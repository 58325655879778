<template>
  <section class="voted-view">
    <figure class="logo-sm">
      <picture>
        <img src="@/assets/images/logo.png" alt="" />
      </picture>
    </figure>
    <div class="title">
      <h1>Voting Results</h1>
      <p>{{ store.user.name }} ({{ store.user.email }})</p>
    </div>
    <div ref="res" class="results">
      <div v-for="(result, index) in results" :key="index" class="voted-view__vote result">
        <h3 class="voted-view__category-name">{{ result.categoryName }}</h3>
        <div class="voted-view__product-image">
          <img :src="result.productImage" />
        </div>
        <div class="voted-view__brand-logo">
          <img :src="result.brandLogo" />
        </div>
        <div class="product-details">
          <p class="voted-view__brand-name">
            <strong>{{ result.brandName }}</strong>
          </p>
          <p class="voted-view__product-name">{{ result.productName }}</p>
        </div>
      </div>
    </div>
    <div class="nav-arrows">
      <button class="prev" @click="scrollByPrev">
        <img src="@/assets/images/left-arrow.svg" alt="" />
      </button>
      <button class="next" @click="scrollByNext">
        <img src="@/assets/images/right-arrow.svg" alt="" />
      </button>
    </div>
  </section>
</template>

<script setup>
import { useStore } from '@/stores/store'
import { ref, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
// import { getUserFromMongo } from '@/lib/mongo'
import { getVote, getResults } from '@/lib/firebase'

//
const store = useStore()
const route = useRoute()
const router = useRouter()

const res = ref(null)
const results = ref([])

const scrollByNext = () => {
  console.log('scrolling..')
  res.value.scrollBy({ left: 400, behaviour: 'smooth' })
}

const scrollByPrev = () => {
  console.log('scrolling..')
  res.value.scrollBy({ left: -300, behaviour: 'smooth' })
}
onMounted(async () => {
  // setting the custom property value for nav arrows
  setNavArrowsWidth()
  // Get id from url
  const uid = route.params.uid
  console.log('uid', uid)

  const data = await getVote(uid)

  const { vote } = data

  if (vote) {
    //update store.user with dbUser data
    console.log('success', vote)
    store.user = vote
    getResults(store.user.vote, results, store)
  } else {
    console.log('fail', vote)

    // const error = {
    //   error: {
    //     status: true,
    //     headline: 'Invalid Link',
    //     message: `This UID(${uid}) does not exist. Please share this message on WhatsApp with +919920019569`,
    //   },
    // }

    router.push({
      name: 'login',
      query: {
        errorStatus: true,
        errorHeadline: 'Invalid Link',
        errorMessage: `This UID(${uid}) does not exist. Please share this message on WhatsApp with +91-9920019569`,
      },
    })
  }
})

const setNavArrowsWidth = () => {
  document.documentElement.style.setProperty('--nav-width', res.value.clientWidth + 'px')

  window.addEventListener('resize', () => {
    document.documentElement.style.setProperty('--nav-width', res.value.clientWidth + 'px')
  })
}
</script>
