<template>
  <div
    class="voting__nomination card"
    :data-product="props.card.productCode"
    :data-category="props.card.categoryCode"
    :class="{ selected: selected, voted: voted }"
  >
    <NominationCardImage
      :card="props.card"
      :selected="selected"
      :voted="voted"
      @toggle-selection="toggleSelection"
    />

    <NominationCardBody :card="props.card" @update-vote="updateVote" />
  </div>
</template>

<script setup>
import { ref, watch, onMounted } from 'vue'

const props = defineProps({
  modelValue: {
    type: Boolean,
  },
  card: {
    type: Object,
    required: true,
  },
  updateChildSelections: {
    type: Boolean,
  },
  updateChildVotes: {
    type: Boolean,
  },
  votedProduct: {
    type: String,
    default: '',
  },
})

import NominationCardImage from './nomination-card-image.vue'
import NominationCardBody from './nomination-card-body.vue'
import { useRoute } from 'vue-router'
import { useStore } from '@/stores/store'
//
const store = useStore()
const route = useRoute()
const emit = defineEmits(['toggle-selection', 'update-vote', 'update-child-votes-status'])
//
const selected = ref(false)
const lastSelected = ref(false)
const voted = ref(false)
//

//
// watch for any updates on cards
watch(
  () => props.updateChildSelections,
  () => {
    if (selected.value === true && lastSelected.value === true) {
      selected.value = true
      lastSelected.value = false
    } else {
      selected.value = false
      lastSelected.value = false
    }
  }
)

// watching the pseudo trigger to pass votedProduct from slides to cards
watch(
  () => props.updateChildVotes,
  () => {
    // console.log('changed')
    // assign voted value based on votedProduct matching cardProduct
    voted.value = props.votedProduct === props.card.productCode
    // if it's voted then selected is true as well... so same condition applied
    selected.value = props.votedProduct === props.card.productCode

    // scroll to top of view
    if (selected.value === true) {
      document.querySelector('.title').scrollIntoView({ behaviour: 'smooth' })
    }
  }
)

//
onMounted(() => {
  // For /nomination/xx-xx route
  if (route.params.nomination) {
    const nominatedProduct = route.params.nomination
    selected.value = props.card.productCode === nominatedProduct
    console.log('nominatedProduct', nominatedProduct, props.card.productCode, selected.value)
  }
  // For /vote/uid route
  if (route.params.uid) {
    // workaround to ensure that on uid route the update happens on load
    emit('update-child-votes-status')
  }
  // the results in console.log will appear as an empty string as the onMounted happens before the props.votedProduct is updated by the parent-Slide
  // console.log('in card', voted.value, selected.value, props.votedProduct, props.card.productCode)
})

// toggle status of card
const toggleSelection = () => {
  // console.log('toggle..')
  // return if the card has votedClass or if the currentSlide already has a vote
  //
  // get vote from store
  const vote = store.user.vote[props.card.categoryCode - 1]

  console.log('vote', vote)

  // if vote exists for current category then return
  if (vote) return

  // toggle status
  selected.value = !selected.value

  // update clicked card to have latest as true and in case the selectedStatus is false then ensure that latestSelection is also false
  selected.value === true ? (lastSelected.value = true) : (lastSelected.value = false)

  // emit toggle-selected event and send boolean value with event
  emit('toggle-selection', selected.value)
}

// emit event to parent slide
const updateVote = () => {
  emit('update-vote')
}

console.log('card - props.card inside 1st card', props.card)
console.log('store-currentSlide inside 1st card', store.currentSlide)
</script>
