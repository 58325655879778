<template>
  <div class="formgroup">
    <input
      ref="input"
      :value="inputValue"
      v-bind="attrs"
      @input="updateValue"
      @countrychange="updateValue"
    />
    <label :for="attrs.id">{{ label }}</label>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useAttrs } from 'vue'
// Intl Tel Input
import intlTelInput from 'intl-tel-input'
import 'intl-tel-input/build/css/intlTelInput.css'

const attrs = useAttrs()
const props = defineProps({
  label: {
    type: String,
    default: '',
  },
  modelValue: {
    type: String,
    required: true,
  },
})

const inputValue = ref(props.modelValue) // Use the prop as the initial value
const input = ref(null)
const mobileNumber = ref(null)
const emit = defineEmits(['update:modelValue'])

onMounted(() => {
  inputValue.value = props.modelValue

  mobileNumber.value = intlTelInput(input.value, {
    utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.12/js/utils.min.js',
    preferredCountries: ['ae', 'in', 'eg'],
    separateDialCode: true,
    initialCountry: 'auto',
    geoIpLookup: async function (callback) {
      let country
      if (localStorage.getItem('userCountry')) {
        country = localStorage.getItem('userCountry')
        callback(country)
      } else {
        try {
          const request = await fetch('https://ipinfo.io/json?token=7a8f85dfeef99e')
          const response = await request.json()
          country = response && response.country ? response.country : 'AE'
        } catch (error) {
          console.log(error)
        } finally {
          localStorage.setItem('userCountry', country)
          callback(country)
        }
      }
    },
  })

  // Set initial value to the input field manually
  input.value.value = inputValue.value
})

const updateValue = (event) => {
  // Update inputValue based on user input
  inputValue.value = event.target.value

  // Update modelValue based on the mobileNumber input
  if (mobileNumber.value && typeof mobileNumber.value.getNumber === 'function') {
    emit('update:modelValue', mobileNumber.value.getNumber())
    console.log(mobileNumber.value, mobileNumber.value.getNumber())
  } else {
    console.log('intlTelInput not initialized yet or getNumber method is unavailable.')
  }
}
</script>
