import { defineStore } from 'pinia'
import { nominations } from '../data/nominations.js'

const categoriesArray = []
const categories = {}

nominations.forEach((nomination) => {
  if (!categories[nomination.categoryCode]) {
    categories[nomination.categoryCode] = {}
  }
  categories[nomination.categoryCode][nomination.productCode] = {
    ...nomination,
  }
})

// console.log(categories)

Object.keys(categories)
  .map((key) => key)
  .sort()
  .forEach((key) => categoriesArray.push({ ...categories[key] }))

const data = [...categoriesArray]

// TODO : Confirm if changing default value of store.currentSlide to 0 is ok and has no side-effects

export const useStore = defineStore('store', {
  state: () => ({
    currentSlide: 0,
    user: {
      uid: '',
      name: '',
      email: '',
      mobile: '',
      profile: '',
      nationality: '',
      organisation: '',
      address: ' ',
      city: ' ',
      state: ' ',
      country: ' ',
      vote: [],
      // currentSlide: 1,
      // isRegistered: null,
      // isRegisteredForVpExpo: null,
      source: 'VP Awards 2024',
      // initiatedAt: null,
      // updatedAt: null,
    },
    imageBlob: '',
    error: {
      status: false,
      headline: '',
      message: '',
    },
    isLoading: false,
    isRegisteringUser: false,
    data,
  }),
})
