<template>
  <RegistrationMessage v-if="store.user.isRegistered" />
  <RegistrationForm v-if="!store.user.isRegistered" />
  <Loader v-if="store.isRegisteringUser" />
  <Modal />
</template>

<script setup>
import RegistrationMessage from './components/registration-message.vue'
import RegistrationForm from './components/registration-form.vue'
import Modal from '@/components/modal/index.vue'
import Loader from '@/components/loader/index.vue'

import { useStore } from '@/stores/store'
import { watch } from 'vue'
const store = useStore()
// const showLoader = ref(false)

// watch(
//   () => store.isRegisteringUser,
//   () => (store.isRegisteringUser ? (showLoader.value = true) : (showLoader.value = false))
// )

//
</script>
