import Mailgun from 'mailgun.js'
import FormData from 'form-data'

const mailgun = new Mailgun(FormData)

console.log('key', process.env.VUE_APP_MAILGUN_API_KEY)

export const mg = mailgun.client({
  username: 'api',
  key: process.env.VUE_APP_MAILGUN_API_KEY,
})
