<template>
  <article class="voting__slider">
    <VotingSlide
      v-for="(slide, key) in store.data"
      :key="key + 1"
      ref="slides"
      :current-slide="store.currentSlide"
      :category-code="(key + 1).toString().padStart(2, '0')"
      :cards="slide"
    />
  </article>
</template>

<script setup>
import VotingSlide from './voting-slide.vue'
import { useStore } from '@/stores/store.js'
import { useRoute } from 'vue-router'
import { onMounted, ref } from 'vue'
const store = useStore()
const route = useRoute()
console.log('slider - store.data', store.data)

// HACK TO ENSURE SLIDER USES VOTE LENGTH ONLY IF ROUTE IS NOT FOR NOMINATION

console.log('route.params.nomination', route.params.nomination)
if (route.params.nomination === undefined) store.currentSlide = store.user.vote.length + 1
console.log('store.currentSlide', store.currentSlide)
const slides = ref(null)

onMounted(() => {
  // console.log('slider-currentSlide onMounted', store.currentSlide)
})
</script>
