<template>
  <teleport to="#modal">
    <section id="error" class="error overlay" :class="{ show: showModal }">
      <article class="modal">
        <figure class="modal__logo">
          <picture>
            <img src="@/assets/images/logo.png" alt="" />
          </picture>
        </figure>
        <h4 class="modal__headline">{{ store.error.headline }}</h4>
        <p class="modal__message">{{ store.error.message }}</p>
        <figure class="modal__close-icon">
          <img src="./images/close-icon.svg" alt="" @click="clearError" />
        </figure>
      </article>
    </section>
  </teleport>
</template>

<script setup>
import { ref, watch } from 'vue'
import { useStore } from '@/stores/store'
//
const store = useStore()
//
watch(
  () => store.error.status,
  () => {
    if (store.error.status === true) {
      showModal.value = true
    } else if (store.error.status === false) {
      clearError()
    }
  }
)
//
const showModal = ref(false)
//
const clearError = () => {
  store.error.status = false
  store.error.headline = ''
  store.error.message = ''
  showModal.value = false
}
</script>
