<template>
  <div class="social">
    <div v-if="pdfExists" class="social__share pdf">
      <!-- <a :href="`${imageUrl}docs/${props.pdf}`" download> -->
      <a :href="`/docs/${props.pdf}`" :download="`${props.brandName}-${props.productName}.pdf`">
        <!-- <img src="./../images/pdf-icon.svg" alt="" /> -->
        <svg width="24px" height="24px" viewBox="0 0 24 24" fill="#000000" class="social-icon">
          <path d="M0 0h24v24H0z" fill="none" />
          <path
            d="M20 2H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-8.5 7.5c0 .83-.67 1.5-1.5 1.5H9v2H7.5V7H10c.83 0 1.5.67 1.5 1.5v1zm5 2c0 .83-.67 1.5-1.5 1.5h-2.5V7H15c.83 0 1.5.67 1.5 1.5v3zm4-3H19v1h1.5V11H19v2h-1.5V7h3v1.5zM9 9.5h1v-1H9v1zM4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm10 5.5h1v-3h-1v3z"
          />
        </svg>
      </a>
    </div>

    <div class="social__share email" @click="verifyStatus">
      <a :href="store.user.email ? mailToLink : null">
        <!-- <img src="./../images/email-icon.svg" alt=""/> -->
        <svg width="24px" height="24px" viewBox="0 0 24 24" fill="#000000" class="social-icon">
          <path d="M0 0h24v24H0z" fill="none" />
          <path d="M2.01 21L23 12 2.01 3 2 10l15 2-15 2z" />
        </svg>
      </a>
    </div>
    <div
      v-if="navigatorExists"
      class="social__share webshare"
      data-post-url="url"
      data-post-title="null"
      @click="shareLink"
    >
      <!-- <img src="./../images/share-icon.svg" alt="" />
       -->
      <svg width="24px" height="24px" viewBox="0 0 24 24" fill="#000000" class="social-icon">
        <path d="M0 0h24v24H0z" fill="none" />
        <path
          d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7s-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92 1.61 0 2.92-1.31 2.92-2.92s-1.31-2.92-2.92-2.92z"
        />
      </svg>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useStore } from '@/stores/store'
import { useRouter } from 'vue-router'
const store = useStore()
const router = useRouter()

// const imageUrl = process.env.VUE_APP_IMAGE_URL

const props = defineProps({
  distributorEmail: {
    type: String,
    default: '',
  },
  pdf: {
    type: String,
    default: null,
  },
  productCode: {
    type: [String, Number],
    default: '',
  },
  productName: {
    type: String,
    default: '',
  },
  brandName: {
    type: String,
    default: '',
  },
})

const mailToLink = `mailto:${props.distributorEmail}?bcc=awards%40fourplusmedia.com&subject=Interested%20in%20your%20product&body=Hi%2C%0APlease%20contact%20me%20for%20your%20product%20nominated%20in%20VP%20Awards%202024%0A%0A${props.brandName}%0A${props.productName}%0A%0AHere%20are%20my%20contact%20details%0A%0AName%20:%20${store.user.name}%0AOrganisation%20:%20${store.user.organisation}%0AEmail%20:%20${store.user.email}%0AMobile%20:%20${store.user.mobile}%0A%0ARegards,%0A${store.user.name}`

const pdfExists = ref(false)

pdfExists.value = props.pdf ? true : false

const emailExists = ref(false)
emailExists.value = props.distributorEmail ? true : false

const navigatorExists = ref(false)
navigatorExists.value = navigator.share ? true : false

// console.log('navigator exists', navigatorExists.value)
// console.log('navigator exists', navigator)
// console.log('props.card', props.card)
const shareLink = async () => {
  console.log('share link')
  const shareObject = {
    files: [new File([store.imageBlob], 'banner.jpg', { type: store.imageBlob.type })],
    title: 'VP Awards 2024',
    text: `Vote for *${props.brandName} ${props.productName}* \n`,
    url: `${process.env.VUE_APP_BASE_URL}#/nomination/${props.productCode}`,
  }
  console.log(shareObject)

  if (navigatorExists.value) {
    await navigator.share(shareObject)
  } else {
    await copyToClipboard(shareObject)
  }
}

const copyToClipboard = async (shareObject) => {
  try {
    await navigator.clipboard.writeText(shareObject.url)
    alert('Link copied to clipboard!')
  } catch (err) {
    console.error('Failed to copy: ', err)
  }
}

const verifyStatus = () => {
  if (!store.user.uid) {
    router.push({ name: 'login' })
    return
  }
}

// mailto:${category[product].distributorEmail}?bcc=awards%40fourplusmedia.com&subject=Interested%20in%20your%20product&body=Hi%2C%0APlease%20contact%20me%20for%20your%20product%20nominated%20in%20VP%20Awards%202022%0A%0A${category[product].brandName}%0A${category[product].productName}
</script>

<style scoped lang="scss">
svg.social-icon {
  fill: grey;

  &:hover {
    fill: var(--primary-color);
  }
}
</style>
