<template>
  <div class="formgroup">
    <select
      id="profile"
      v-model="selected"
      name="profile"
      :disabled="disabled"
      @change="updateValue"
    >
      <option value="null">Select Type</option>
      <option value="Optical Retailer">Optical Retailer</option>
      <option value="Optometrist">Optometrist</option>
      <option value="Ophthalmologist">Ophthalmologist</option>
      <option value="Manufacturer">Manufacturer</option>
      <option value="Distributor / Wholesaler">Distributor / Wholesaler</option>
      <option value="Technician">Technician</option>
      <option value="Student">Student</option>
      <option value="Other">Other</option>
    </select>
    <label for="profile">Profile</label>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
  profile: {
    type: String,
    required: true,
  },
})

const emit = defineEmits(['update:profile'])

const selected = ref('null')

const updateValue = () => {
  console.log('selected1', selected.value)
  console.log('props', props.profile)
  emit('update:profile', selected.value)
}
onMounted(() => {
  selected.value = props.profile
})
</script>
