<template>
  <button type="submit">
    <SpinLoader class="spin" :class="{ animate: store.isLoading }" />
    <slot v-if="!store.isLoading"> Submit </slot>
    <!-- <DoubleArrow class="arrow" /> -->
  </button>
</template>

<script setup>
import SpinLoader from './spin-loader.vue'
// import DoubleArrow from './double-arrow.vue'
import { useStore } from '@/stores/store'

const store = useStore()
</script>

<style lang="scss">
.spin {
  width: 1rem;
  height: 1rem;
  display: none;
  &.animate {
    display: block;
    animation: var(--animation-spin);
  }
}
</style>
