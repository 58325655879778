<template>
  <div
    class="voting__slide scrollbars-default"
    :data-category="props.cards.categoryCode"
    :data-segment="props.cards.segmentCode"
    :style="{
      transform: slidePosition,
    }"
    :class="{
      'active-selected': selected,
      'active-voted': voted,
    }"
  >
    <VotingNominationTitle
      ref="cardTitle"
      :category-code="props.categoryCode"
      :category-name="
        props.cards[`${props.categoryCode}-01`]
          ? props.cards[`${props.categoryCode}-01`].categoryName
          : ''
      "
    />

    <VotingNominationCard
      v-for="card in props.cards"
      :key="card.productCode"
      :update-child-selections="updateChildSelections"
      :update-child-votes="updateChildVotes"
      :card="card"
      :voted-product="votedProduct"
      @update-vote="updateVote"
      @toggle-selection="toggleSelection"
      @update-child-votes-status="updateChildVotesStatus"
    />
  </div>
</template>
<script setup>
//

// v-for="card in Object.values(props.cards).filter((card) => typeof card === 'object')"
// console.log('props.cards[`${props.categoryCode}-01`]', props.cards[`${props.categoryCode}-01`])
// console.log('props.cards[`${props.categoryCode}-01`]', props.categoryCode)

import { ref, computed, onMounted } from 'vue'
import { useStore } from '@/stores/store'
import VotingNominationTitle from './voting-nomination-title.vue'
import VotingNominationCard from './voting-nomination-card.vue'
import { useRoute, useRouter } from 'vue-router'
//
const route = useRoute()
const router = useRouter()
const store = useStore()
const props = defineProps({
  cards: {
    type: Object,
    required: true,
  },
  currentSlide: {
    type: Number,
    required: true,
  },
  categoryCode: {
    type: String,
    required: true,
  },
})
const cardTitle = ref(null)
// console.log('props', props.cards['10'].categoryName)
// console.log('card-title', cardTitle)
// console.log('props.cards', props.cards, props.cards[`${props.categoryCode}-01`].categoryCode)
//
const votedProduct = ref('')
const votedCategory = ref('')

const selected = ref(false)
const voted = ref(false)

onMounted(() => {
  console.log('card-title on mounted', cardTitle)
  console.log('Pinia store state:', store.state)
  // console.log('props.cards:', props.cards[`${props.categoryCode}-01`])
  console.log('props.cards:', props.cards)

  // For /nomination/xx-xx route
  if (route.params.nomination) {
    const nominatedProduct = route.params.nomination
    const [nominatedCategory] = nominatedProduct.split('-')
    selected.value = nominatedCategory === props.categoryCode
    console.log('onMounted in slide', nominatedProduct, nominatedCategory, selected.value)
  }
  //
  // For /vote/uid route
  if (route.params.uid) {
    if (!store.user.uid) {
      store.error.state = 'true'
      store.error.headline = 'Sorry'
      store.error.message = `The URL/User (UID :${route.params.uid} was not found. It could be that this user has already completed the voting process. Please check your email to confirm or share this message by Whatsapp to +91-9920019569)`
      router.push({ name: 'login' })
    }

    // store.currentSlide = store.user.vote.length + 1
    // console.log('slide-store.currentSlide on 1st slide', store.currentSlide)

    // get vote from store
    const vote = store.user.vote[+props.categoryCode - 1]
      ? store.user.vote[+props.categoryCode - 1]
      : null

    // get categoryCode and productCode
    const { categoryCode, productCode } = vote ? vote : { categoryCode: null, productCode: null }

    // this was not necessary but just did it nonetheless
    votedCategory.value = categoryCode
    votedProduct.value = productCode
    // assign selected and voted values
    selected.value = votedCategory.value === props.categoryCode
    voted.value = votedCategory.value === props.categoryCode

    // // trigger a change so that the child component can react to it on watch
    // updateChildVotes.value = !updateChildVotes.value
  }
})

/*
slide position
*/
const slidePosition = computed(() => {
  console.log('slidePosition', props.categoryCode, props.currentSlide)
  return `translatex(${(+props.categoryCode - props.currentSlide) * 100}%)`
})

const updateChildSelections = ref(false)
const updateChildVotes = ref(false)

const toggleSelection = (selectedStatus) => {
  // change status to trigger watch on child image component
  updateChildSelections.value = !updateChildSelections.value

  //update the value so that class of active-selected is updated
  selected.value = selectedStatus

  // scroll to top on selection
  if (selectedStatus === true) {
    // Refer to this link for use of $el : https://stackoverflow.com/questions/67033933/cant-use-template-ref-on-component-in-vue-3-composition-api
    // console.log(cardTitle.value.$el)
    cardTitle.value.$el.scrollIntoView({ behaviour: 'smooth' })
  }
}

const updateVote = () => {
  // get vote from store
  const vote = store.user.vote[+props.categoryCode - 1]

  console.log('store.user.vote', store.user.vote)
  console.log('props.categoryCode', props.categoryCode)
  console.log('vote in updateVote', vote)
  // get categoryCode and productCode
  const { categoryCode, productCode } = vote
  // this was not necessary but just did it nonetheless
  votedCategory.value = categoryCode
  votedProduct.value = productCode
  // assign selected and voted values
  console.log('testing vote and props values', votedCategory.value, props.categoryCode)

  selected.value = votedCategory.value === props.categoryCode
  voted.value = votedCategory.value === props.categoryCode
  // trigger a change so that the child component can react to it on watch
  updateChildVotes.value = !updateChildVotes.value
  // scroll to top on selection
  cardTitle.value.$el.scrollIntoView({ behaviour: 'smooth' })
}

const updateChildVotesStatus = () => {
  // console.log('status updated')
  // trigger a change so that the child component can react to it on watch
  updateChildVotes.value = !updateChildVotes.value
}
</script>
