// import { mg } from './../components/mailgun/index.js'
// import { useStore } from '@/stores/store'
// const { store } = useStore()

// // SEND EMAIL
// export const emailMessage = async (message) => {
//   mg.messages
//     .create('mailgun.alimbolar.com', message)
//     .then((msg) => console.log(msg)) // logs response data
//     .catch((err) => console.error(err)) // logs any error
// }

// VALIDATE LOGIN

export const validateUser = async (user, store) => {
  // Create user object with only the fields that need to be validated on login
  // const user = {
  //   name: newUser.name,
  //   email: newUser.email,
  //   mobile: newUser.mobile,
  //   organisation: newUser.organisation,
  //   profile: newUser.profile,
  //   nationality: newUser.nationality,
  // }

  console.log('validate user is triggered')
  const invalidFields = Object.entries(user)
    .map(([key, value]) => {
      console.log('key', key)
      console.log('value', value)
      if (value === null || value === undefined || value.trim() === '' || value == 'null') {
        return key
      }
    })
    .filter((key) => typeof key !== 'undefined')

  if (invalidFields.length) {
    store.error.status = true
    store.error.headline = 'Error'
    store.error.message = `Please update these fields :  ${invalidFields.join(`, `).toUpperCase()}`

    return false
  }
  return true
}
