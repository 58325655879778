<template>
  <section class="result-view">
    <h1>Results for your voting</h1>

    <pre>{{ finalResult }}</pre>
  </section>
</template>

<script setup>
import { reactive, onMounted } from 'vue'

// import { getResults } from '@/lib/firebase'

// import { users } from './../../data/data'

//
let votes = reactive([])
let finalResult = reactive({})

let voteObject = {}

//
onMounted(async () => {
  //
  votes = await getResults()

  console.log(votes)

  // users.forEach((user) => {
  //   votes.push(...user.vote)
  // })

  // Loop through votes to create An Object with all categories as key
  votes.forEach((vote) => {
    if (!voteObject[vote.categoryCode]) {
      const newObject = { [vote.categoryCode]: {} }
      voteObject = { ...voteObject, ...newObject }
    }
  })

  // Loop again through votes and get the product count and update in the categories
  votes.forEach((vote) => {
    if (voteObject[vote.categoryCode][vote.productCode]) {
      voteObject[vote.categoryCode][vote.productCode] += 1
      // console.log('existing', voteObject[vote.categoryCode][vote.productCode])
    } else {
      voteObject[vote.categoryCode][vote.productCode] = 1
      // console.log('new', voteObject[vote.categoryCode][vote.productCode])
    }
  })
  console.log('voteObject', voteObject)

  finalResult = { ...voteObject }
})

const getResults = async () => {
  // Create API URL for fetch
  const url = '/api/mongoGetResults'
  // Create option object for fetch
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  }
  // Get Response
  const response = await fetch(url, options)
  // Convert response to data
  const data = await response.json()
  // Check if data is valid
  if (data.status === 'success') {
    return data.votes
  } else {
    console.log(data.error)
    return data.error
  }
}
</script>
