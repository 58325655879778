// GET USER FROM RTDB
export const getUserFromRTDB = async (uid) => {
  // const response = await fetch('/api/firebaseGetUser', {
  const response = await fetch('/api/firestoreGetUser', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ uid }),
  })
  const data = await response.json()
  // console.log('response', response)
  // console.log('data', data)
  return data
}

//CREATE USER ON RTDB
export const createUserOnRTDB = async (user) => {
  console.log('user in crud-create', user)
  // const response = await fetch('/api/firebaseCreateUser', {
  const response = await fetch('/api/firestoreCreateUser', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ user }),
  })
  const data = await response.json()
  console.log('response.ok in utils', response.ok)
  console.log('data.status in utils', data.status)
  return data
}

//CREATE USER ON RTDB
export const createRegistration = async (user) => {
  console.log('user in crud-create', user)
  // const response = await fetch('/api/firebaseCreateUser', {
  const response = await fetch('/api/firestoreCreateRegistration', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ user }),
  })
  const data = await response.json()
  console.log('response.ok in utils', response.ok)
  console.log('data.status in utils', data.status)
  return data
}

// // REMOVE USER FROM RTDB
// export const removeUserFromRTDB = async (uid) => {
//   // const response = await fetch('/api/firebaseRemoveUser', {
//   const response = await fetch('/api/firestoreRemoveUser', {
//     method: 'DELETE',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify({ uid }),
//   })
//   const data = await response.json()
//   console.log('response', response)
//   console.log('data', data)
// }

// MOVE USER FROM USERS TO VOTES
export const moveUser = async (uid) => {
  const response = await fetch('/api/firestoreMoveUser', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ uid }),
  })
  const data = await response.json()
  console.log('moveUser', data)
  return data
}

// //CREATE VOTE
// export const createVote = async (user) => {
//   console.log('vote in crud-create', user)
//   // const response = await fetch('/api/firebaseCreateUser', {
//   const response = await fetch('/api/firestoreCreateVote', {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify({ vote: user }),
//   })
//   const data = await response.json()
//   console.log('response.ok in utils', response.ok)
//   console.log('data.status in utils', data.status)
//   return data
// }

// UPDATE VOTE ARRAY
export const updateVote = async (uid, currentVote) => {
  const response = await fetch('/api/firestoreUpdateVote', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ uid, currentVote }),
  })

  const data = await response.json()

  console.log('current vote response', data)

  return data
}

//  RESULTS

export const getResults = (vote, results, store) => {
  console.log('getting results....')
  console.log(vote)

  console.log(process.env.VUE_APP_IMAGE_URL)

  results.value = vote.map((eachVote, index) => {
    // const segmentCode = `${store.data[index].segmentCode}`
    // console.log(segmentCode)
    const productCode = eachVote.productCode
    console.log(productCode)
    return {
      categoryName: `${store.data[index][productCode].categoryName}`,
      productName: `${store.data[index][productCode].productName}`,
      productImage: `${process.env.VUE_APP_IMAGE_URL}products/md/${store.data[index][productCode].productImage}`,
      brandName: `${store.data[index][productCode].brandName}`,
      brandLogo: `${process.env.VUE_APP_IMAGE_URL}logos/${store.data[index][productCode].brandLogo}`,
    }
  })
  console.log('results', results.value)
}

export const getVote = async (uid) => {
  // const response = await fetch('/api/firebaseGetUser', {
  const response = await fetch('/api/firestoreGetVote', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ uid: uid }),
  })
  const data = await response.json()
  console.log('response', response)
  console.log('data', data)

  return data
}
