<template>
  <article class="registration__form">
    <figure class="logo-sm">
      <picture>
        <img src="@/assets/images/logo.png" alt="VP Awards Logo" width="400" height="100" />
      </picture>
    </figure>
    <h3>Please complete the form below and submit to confirm your vote</h3>
    <form
      ref="registrationForm"
      class="form confirm-user"
      enctype="multipart/form-data"
      novalidate
      @submit.prevent="registerUser(store.user)"
    >
      <legend>Personal Details</legend>

      <BaseInput
        id="name"
        v-model="store.user.name"
        label="Name"
        type="text"
        name="name"
        placeholder="Your Name"
        minlength="2"
        disabled="true"
      />
      <BaseInput
        id="email"
        v-model="store.user.email"
        label="Email"
        type="email"
        name="email"
        placeholder="Email"
        disabled="true"
      />
      <BaseInput
        id="mobile"
        v-model="store.user.mobile"
        label="Mobile"
        type="tel"
        name="mobile"
        placeholder="Mobile"
        pattern="[0-9]{10}"
        disabled="true"
      />
      <SelectNationality v-model:nationality="store.user.nationality" :disabled="true" />
      <SelectProfile v-model:profile="store.user.profile" :disabled="true" />

      <div class="formgroup vpexpo profile">
        <label for="vp-expo-registered" class="vp-expo-registered">
          <input
            id="vp-expo-registered"
            v-model="isRegisteredForVpExpo"
            type="checkbox"
            name="vpExpoRegistered"
            checked
          />
          <span> Register For VisionPlus Expo Dubai 2024</span></label
        >
      </div>

      <hr />

      <legend>Organisation Details</legend>

      <BaseInput
        id="organisation"
        v-model="store.user.organisation"
        type="text"
        label="Organisation"
        name="organisation"
        placeholder="Organisation"
        minlength="2"
      />

      <BaseInput
        id="address"
        v-model="store.user.address"
        label="Address"
        type="text"
        name="address"
        placeholder="Address"
      />

      <BaseInput
        id="city"
        v-model="store.user.city"
        label="City"
        type="text"
        name="city"
        placeholder="City"
      />

      <BaseInput
        id="state"
        v-model="store.user.state"
        label="State"
        type="text"
        name="state"
        placeholder="State"
      />

      <BaseInput
        id="country"
        v-model="store.user.country"
        label="Country"
        type="text"
        name="country"
        placeholder="Country"
      />

      <Button value="Confirm Vote" />
    </form>
  </article>
</template>

<script setup>
import BaseInput from '@/components/form/base-input.vue'
import SelectNationality from '@/components/form/select-nationality.vue'
import SelectProfile from '@/components/form/select-profile.vue'
import Button from '@/components/form/button.vue'
import { useStore } from '@/stores/store'
import { onBeforeUnmount, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { validateUser } from '@/lib/utils'
import { createRegistration } from '@/lib/firebase'

//
const store = useStore()
const route = useRoute()

//
// watch(
//   () => store.user.isRegistered,
//   () => {
//     console.log('isRegistered', store.user.isRegistered)
//     if (store.user.isRegistered === true) {
//       sendRegisterEmail()
//     }
//   }
// )
//
const isRegisteredForVpExpo = ref(true)
const registrationForm = ref(null)

onBeforeUnmount(() => {
  localStorage.setItem('user', JSON.stringify(store.user))
})

onMounted(() => {
  console.log('on mounted - register', route.params)
  if (route.params.votedButNotRegistered) {
    store.error.status = true
    store.error.headline = 'Already Voted'
    store.error.message = `You have already voted. But your final registration is pending.(UID:${store.user.uid}). Please submit the registration form here to confirm your vote.`
  }
})

const registerUser = async () => {
  // Added ternary option for address, city, state and country to avoid error
  const registration = {
    uid: store.user.uid,
    name: store.user.name,
    email: store.user.email,
    mobile: store.user.mobile,
    organisation: store.user.organisation,
    profile: store.user.profile,
    nationality: store.user.nationality,
    address: store.user.address ? store.user.address : ' ',
    city: store.user.city ? store.user.city : ' ',
    state: store.user.state ? store.user.state : ' ',
    country: store.user.country ? store.user.country : ' ',
  }

  const validation = await validateUser(registration, store)
  console.log('validation', validation)
  if (!validation) return

  store.isLoading = true
  store.isRegisteringUser = true
  console.log('store.isLoading true, store.isRegisteringUser')

  console.log('registering...')
  registration.isRegisteredForVpExpo = isRegisteredForVpExpo.value
  registration.updatedAt = new Date().toJSON()

  const data = await createRegistration(registration)
  if (data.status === 'success') {
    store.user.isRegistered = true
    store.isLoading = false
    store.isRegisteringUser = false
    // await sendRegisterEmail()
    console.log('user registered')
    console.log('store.isLoading false - success')
    return true
  } else {
    console.log('error while registering user', data)
    registration.isRegistered = false
    store.user.isRegistered = false
    store.isLoading = false
    store.isRegisteringUser = false
    console.log('store.isLoading false - not success')
    store.error.status = true
    store.error.headline = 'Sorry'
    store.error.message = `There was an error registering user (UID:${store.user.uid}). Please share screenshot on +91-9920019569 `

    return false
  }
}
</script>

<style scoped lang="scss">
label.vp-expo-registered {
  display: flex;
  justify-content: center;
  align-items: center;

  input {
    flex-basis: 10%;
    accent-color: var(--secondary-color);
  }
  span {
    flex-basis: 90%;
  }
}
</style>
