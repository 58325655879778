<template>
  <div class="intro">
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 504 144"
      enable-background="new 0 0 504 144"
      xml:space="preserve"
    >
      <g id="vp-0">
        <path
          fill="#00616F"
          d="M61.7,108.979c0-0.416,0.019-0.8,0.071-1.106c0.041-0.237,0.249-0.42,0.47-0.559
		c0.222-0.139,0.508-0.244,0.835-0.325c0.652-0.163,1.468-0.229,2.231-0.23c0.78,0.001,1.5,0.069,1.943,0.189
		c0.149,0.041,0.267,0.086,0.359,0.15c0.167,0.101-0.114,0.286-0.114,0.286l-0.073,0.003c0,0,0.033-0.05,0.022-0.058
		c-0.023-0.017-0.064-0.039-0.12-0.06c-0.112-0.042-0.279-0.084-0.481-0.118c-0.406-0.068-0.956-0.108-1.536-0.108
		c-0.56,0-1.149,0.038-1.67,0.122c-0.519,0.083-0.971,0.216-1.245,0.389c-0.184,0.117-0.279,0.24-0.3,0.367
		c-0.048,0.278-0.067,0.651-0.067,1.058c0,1.232,0.201,2.403,0.313,2.888c0.027,0.241,0.148,0.3,0.148,0.3l-0.053,0.003l-0.28,0.03
		c0,0-0.021-0.005-0.023-0.02C62.104,111.968,61.7,110.311,61.7,108.979"
        />
        <path
          fill="#00616F"
          d="M58.938,110.86c0.002-0.146,0.077-0.289,0.216-0.431c0.14-0.142,0.348-0.283,0.643-0.411
		c0.59-0.254,1.075-0.453,2.508-0.453c2.184,0,2.562,0.285,2.602,0.512c0,0-0.008-0.043-0.044-0.074
		c-0.036-0.032-0.088-0.042-0.088-0.042c-0.766-0.079-2.019-0.112-2.019-0.112c-2.109-0.003-3.083,0.45-3.4,0.779
		c-0.107,0.107-0.136,0.198-0.134,0.232c-0.008,0.043,0.025,0.064,0.069,0.074C59.247,110.955,58.938,111.053,58.938,110.86"
        />
        <path
          fill="#00616F"
          d="M63.225,113.446c0-0.824,0.126-2.35,0.126-2.352l0.011-0.135h0.136h0.022c0.096,0,0.523-0.004,1.013-0.069
		c0.489-0.063,1.044-0.192,1.381-0.416c0.226-0.152,0.346-0.319,0.347-0.545c0-0.2-0.116-0.369-0.345-0.528
		c-0.227-0.156-0.553-0.283-0.903-0.378c-0.701-0.191-1.493-0.26-1.807-0.28c-0.089-0.006-0.305-0.063-0.31-0.098
		c-0.007-0.046-0.065-0.043-0.065-0.043l0.042-0.038c0.008,0.001,1.652-0.069,2.677,0.324c0.342,0.132,0.643,0.302,0.832,0.546
		c0.107,0.14,0.174,0.309,0.173,0.495c0.002,0.348-0.206,0.614-0.479,0.791c-0.276,0.18-0.625,0.293-0.98,0.371
		c-0.591,0.13-1.321,0.098-1.585-0.003l0.123,0.082c-0.012,0.152-0.032,0.501-0.052,0.811c-0.031,0.482-0.063,1.066-0.063,1.465
		c0,0.168,0.007,0.31,0.018,0.377c0.002,0.011,0.046,0.027,0.046,0.027l-0.255,0.049C63.231,113.81,63.226,113.655,63.225,113.446"
        />
        <path
          fill="#00616F"
          d="M63.616,104.642c-2.843,0-5.148,2.305-5.148,5.148c0,2.843,2.305,5.148,5.148,5.148
		c2.844,0,5.149-2.305,5.149-5.148C68.765,106.947,66.46,104.642,63.616,104.642 M69.052,109.79c0,3.002-2.434,5.436-5.436,5.436
		c-3.002,0-5.435-2.434-5.435-5.436s2.433-5.436,5.435-5.436C66.618,104.354,69.052,106.788,69.052,109.79"
        />
        <polygon
          fill="#00616F"
          points="12.487,20.871 114.745,20.871 114.745,93.725 63.617,123.129 12.487,93.725 	"
        />
        <path
          fill="#FFFFFF"
          d="M61.7,108.979c0-0.416,0.019-0.8,0.071-1.106c0.041-0.237,0.249-0.42,0.47-0.559
		c0.222-0.139,0.508-0.244,0.835-0.325c0.652-0.163,1.468-0.229,2.231-0.23c0.78,0.001,1.5,0.069,1.943,0.189
		c0.149,0.041,0.267,0.086,0.359,0.15c0.167,0.101-0.114,0.286-0.114,0.286l-0.073,0.003c0,0,0.033-0.05,0.022-0.058
		c-0.023-0.017-0.064-0.039-0.12-0.06c-0.112-0.042-0.279-0.084-0.481-0.118c-0.406-0.068-0.956-0.108-1.536-0.108
		c-0.56,0-1.149,0.038-1.67,0.122c-0.519,0.083-0.971,0.216-1.245,0.389c-0.184,0.117-0.279,0.24-0.3,0.367
		c-0.048,0.278-0.067,0.651-0.067,1.058c0,1.232,0.201,2.403,0.313,2.888c0.027,0.241,0.148,0.3,0.148,0.3l-0.053,0.003l-0.28,0.03
		c0,0-0.021-0.005-0.023-0.02C62.104,111.968,61.7,110.311,61.7,108.979"
        />
        <path
          fill="#FFFFFF"
          d="M58.938,110.86c0.002-0.146,0.077-0.289,0.216-0.431c0.14-0.142,0.348-0.283,0.643-0.411
		c0.59-0.254,1.075-0.453,2.508-0.453c2.184,0,2.562,0.285,2.602,0.512c0,0-0.008-0.043-0.044-0.074
		c-0.036-0.032-0.088-0.042-0.088-0.042c-0.766-0.079-2.019-0.112-2.019-0.112c-2.109-0.003-3.083,0.45-3.4,0.779
		c-0.107,0.107-0.136,0.198-0.134,0.232c-0.008,0.043,0.025,0.064,0.069,0.074C59.247,110.955,58.938,111.053,58.938,110.86"
        />
        <path
          fill="#FFFFFF"
          d="M63.225,113.446c0-0.824,0.126-2.35,0.126-2.352l0.011-0.135h0.136h0.022c0.096,0,0.523-0.004,1.013-0.069
		c0.489-0.063,1.044-0.192,1.381-0.416c0.226-0.152,0.346-0.319,0.347-0.545c0-0.2-0.116-0.369-0.345-0.528
		c-0.227-0.156-0.553-0.283-0.903-0.378c-0.701-0.191-1.493-0.26-1.807-0.28c-0.089-0.006-0.305-0.063-0.31-0.098
		c-0.007-0.046-0.065-0.043-0.065-0.043l0.042-0.038c0.008,0.001,1.652-0.069,2.677,0.324c0.342,0.132,0.643,0.302,0.832,0.546
		c0.107,0.14,0.174,0.309,0.173,0.495c0.002,0.348-0.206,0.614-0.479,0.791c-0.276,0.18-0.625,0.293-0.98,0.371
		c-0.591,0.13-1.321,0.098-1.585-0.003l0.123,0.082c-0.012,0.152-0.032,0.501-0.052,0.811c-0.031,0.482-0.063,1.066-0.063,1.465
		c0,0.168,0.007,0.31,0.018,0.377c0.002,0.011,0.046,0.027,0.046,0.027l-0.255,0.049C63.231,113.81,63.226,113.655,63.225,113.446"
        />
        <path
          fill="#FFFFFF"
          d="M63.616,104.642c-2.843,0-5.148,2.305-5.148,5.148c0,2.843,2.305,5.148,5.148,5.148
		c2.844,0,5.149-2.305,5.149-5.148C68.765,106.947,66.46,104.642,63.616,104.642 M69.052,109.79c0,3.002-2.434,5.436-5.436,5.436
		c-3.002,0-5.435-2.434-5.435-5.436s2.433-5.436,5.435-5.436C66.618,104.354,69.052,106.788,69.052,109.79"
        />
        <path
          fill="#00616F"
          d="M68.143,29.906c0,0-8.344-0.745-10.635,6.417c-2.29,7.163-2.945-1.818-2.945-1.818l2.618-5.908l9.98-0.654
		L68.143,29.906z"
        />
        <path
          fill="#00616F"
          d="M78.802,80.462c0,0-0.548,9.49-8.999,9.49c-8.45,0-2.618,1.308-2.618,1.308h12.926V80.462H78.802z"
        />
        <path
          fill="#FFFFFF"
          d="M92.645,98.792h1.105v-2.174c0-0.524,0.255-0.793,0.669-0.793c0.415,0,0.647,0.269,0.647,0.793v2.174h1.106
		v-2.174c0-0.524,0.254-0.793,0.669-0.793c0.414,0,0.647,0.269,0.647,0.793v2.174h1.105v-2.538c0-0.938-0.494-1.432-1.316-1.432
		c-0.531,0-0.938,0.218-1.28,0.618c-0.204-0.393-0.589-0.618-1.098-0.618c-0.56,0-0.894,0.298-1.149,0.625v-0.553h-1.105V98.792z
		 M89.743,97.927c-0.61,0-1.025-0.502-1.025-1.077v-0.014c0-0.575,0.378-1.062,1.011-1.062c0.611,0,1.025,0.502,1.025,1.076v0.015
		C90.754,97.439,90.376,97.927,89.743,97.927 M89.729,98.879c1.214,0,2.116-0.909,2.116-2.029v-0.014
		c0-1.12-0.894-2.014-2.102-2.014c-1.214,0-2.116,0.909-2.116,2.028v0.015C87.627,97.985,88.522,98.879,89.729,98.879
		 M85.612,98.879c0.778,0,1.215-0.298,1.593-0.712l-0.647-0.655c-0.269,0.255-0.524,0.415-0.895,0.415
		c-0.603,0-0.989-0.48-0.989-1.077v-0.014c0-0.575,0.393-1.062,0.946-1.062c0.392,0,0.64,0.167,0.887,0.429l0.676-0.727
		c-0.364-0.407-0.822-0.654-1.556-0.654c-1.193,0-2.043,0.916-2.043,2.028v0.015C83.584,97.977,84.442,98.879,85.612,98.879
		 M81.795,98.792h1.178v-1.163h-1.178V98.792z M78.806,97.556c-0.538,0-0.945-0.364-0.945-0.902v-0.015
		c0-0.53,0.407-0.901,0.945-0.901c0.538,0,0.953,0.371,0.953,0.901v0.015C79.759,97.185,79.344,97.556,78.806,97.556 M78.675,99.97
		c0.757,0,1.317-0.16,1.68-0.524c0.327-0.327,0.495-0.836,0.495-1.534v-3.018h-1.106v0.509c-0.298-0.327-0.654-0.581-1.258-0.581
		c-0.894,0-1.73,0.654-1.73,1.817v0.015c0,1.156,0.821,1.818,1.73,1.818c0.589,0,0.946-0.24,1.273-0.633v0.189
		c0,0.698-0.356,1.062-1.113,1.062c-0.516,0-0.916-0.131-1.316-0.356l-0.378,0.829C77.446,99.825,78.043,99.97,78.675,99.97
		 M74.065,98.101c-0.356,0-0.603-0.174-0.603-0.473v-0.014c0-0.349,0.29-0.538,0.763-0.538c0.269,0,0.516,0.058,0.706,0.145v0.197
		C74.931,97.818,74.581,98.101,74.065,98.101 M73.731,98.865c0.538,0,0.909-0.197,1.178-0.495v0.422h1.069V96.53
		c0-0.523-0.131-0.952-0.422-1.243c-0.276-0.276-0.713-0.436-1.316-0.436c-0.662,0-1.084,0.123-1.513,0.312l0.277,0.844
		c0.356-0.131,0.654-0.211,1.076-0.211c0.552,0,0.836,0.254,0.836,0.713v0.065c-0.276-0.094-0.56-0.16-0.953-0.16
		c-0.923,0-1.57,0.393-1.57,1.244v0.014C72.393,98.443,72.996,98.865,73.731,98.865 M65.717,98.792h1.106v-2.174
		c0-0.524,0.254-0.793,0.669-0.793c0.414,0,0.647,0.269,0.647,0.793v2.174h1.105v-2.174c0-0.524,0.255-0.793,0.669-0.793
		c0.415,0,0.647,0.269,0.647,0.793v2.174h1.106v-2.538c0-0.938-0.495-1.432-1.317-1.432c-0.53,0-0.938,0.218-1.279,0.618
		c-0.204-0.393-0.589-0.618-1.099-0.618c-0.559,0-0.894,0.298-1.148,0.625v-0.553h-1.106V98.792z M63.434,98.865
		c0.843,0,1.491-0.393,1.491-1.258v-0.015c0-0.713-0.633-0.974-1.178-1.156c-0.422-0.146-0.793-0.247-0.793-0.466v-0.014
		c0-0.153,0.138-0.269,0.407-0.269c0.284,0,0.669,0.138,1.055,0.363l0.421-0.763c-0.421-0.284-0.959-0.451-1.454-0.451
		c-0.785,0-1.425,0.444-1.425,1.236v0.015c0,0.756,0.618,1.011,1.163,1.171c0.429,0.13,0.807,0.21,0.807,0.45v0.015
		c0,0.174-0.145,0.291-0.465,0.291c-0.364,0-0.807-0.16-1.229-0.466l-0.473,0.728C62.278,98.683,62.889,98.865,63.434,98.865
		 M58.831,98.865c0.56,0,0.887-0.299,1.142-0.626v0.553h1.105v-3.898h-1.105v2.175c0,0.523-0.269,0.792-0.691,0.792
		s-0.669-0.269-0.669-0.792v-2.175h-1.105v2.524C57.508,98.312,57.995,98.865,58.831,98.865 M55.392,98.792h1.105v-5.308h-1.105
		V98.792z M52.469,97.927c-0.517,0-0.946-0.429-0.946-1.077v-0.014c0-0.647,0.429-1.076,0.946-1.076
		c0.516,0,0.952,0.429,0.952,1.076v0.014C53.421,97.505,52.985,97.927,52.469,97.927 M50.432,99.955h1.106v-1.672
		c0.262,0.313,0.625,0.582,1.214,0.582c0.924,0,1.774-0.713,1.774-2.015v-0.014c0-1.302-0.865-2.014-1.774-2.014
		c-0.574,0-0.945,0.269-1.214,0.632v-0.56h-1.106V99.955z M45.902,98.792h1.106v-2.174c0-0.524,0.269-0.793,0.69-0.793
		c0.422,0,0.669,0.269,0.669,0.793v2.174h1.106v-2.523c0-0.895-0.488-1.447-1.324-1.447c-0.56,0-0.887,0.298-1.141,0.625v-0.553
		h-1.106V98.792z M43.001,97.927c-0.611,0-1.025-0.502-1.025-1.077v-0.014c0-0.575,0.378-1.062,1.01-1.062
		c0.611,0,1.026,0.502,1.026,1.076v0.015C44.012,97.439,43.634,97.927,43.001,97.927 M42.986,98.879
		c1.215,0,2.116-0.909,2.116-2.029v-0.014c0-1.12-0.894-2.014-2.101-2.014c-1.214,0-2.116,0.909-2.116,2.028v0.015
		C40.885,97.985,41.779,98.879,42.986,98.879 M38.929,98.792h1.105v-3.898h-1.105V98.792z M38.9,94.465h1.163v-0.982H38.9V94.465z
		 M36.595,98.865c0.843,0,1.49-0.393,1.49-1.258v-0.015c0-0.713-0.632-0.974-1.178-1.156c-0.421-0.146-0.792-0.247-0.792-0.466
		v-0.014c0-0.153,0.138-0.269,0.407-0.269c0.284,0,0.669,0.138,1.054,0.363l0.422-0.763c-0.422-0.284-0.96-0.451-1.454-0.451
		c-0.786,0-1.425,0.444-1.425,1.236v0.015c0,0.756,0.618,1.011,1.163,1.171c0.429,0.13,0.807,0.21,0.807,0.45v0.015
		c0,0.174-0.145,0.291-0.465,0.291c-0.364,0-0.807-0.16-1.229-0.466l-0.473,0.728C35.439,98.683,36.049,98.865,36.595,98.865
		 M33.083,98.792h1.105v-3.898h-1.105V98.792z M33.053,94.465h1.163v-0.982h-1.163V94.465z M29.854,98.821h1.003l1.535-3.927h-1.149
		l-0.88,2.611l-0.873-2.611h-1.17L29.854,98.821z"
        />
        <g>
          <defs>
            <polygon
              id="SVGID_1_"
              points="12.487,20.871 12.487,93.724 63.617,123.129 114.745,93.724 114.745,20.871 			"
            />
          </defs>
          <clipPath id="SVGID_2_">
            <use xlink:href="#SVGID_1_" overflow="visible" />
          </clipPath>
          <g clip-path="url(#SVGID_2_)">
            <text transform="matrix(1 0 0 1 19.272 90.0312)">
              <tspan
                x="0"
                y="0"
                fill="#FFFFFF"
                font-family="'Raleway-Black'"
                font-size="84.5153px"
                letter-spacing="-19"
              >
                V
              </tspan>
              <tspan
                x="37.778"
                y="0"
                fill="#FFFFFF"
                font-family="'Raleway-Black'"
                font-size="84.5153px"
              >
                P
              </tspan>
            </text>
          </g>
          <defs>
            <filter
              id="Adobe_OpacityMaskFilter"
              filterUnits="userSpaceOnUse"
              x="46.743"
              y="58.317"
              width="16.319"
              height="32.714"
            >
              <feFlood style="flood-color: white; flood-opacity: 1" result="back" />
              <feBlend in="SourceGraphic" in2="back" mode="normal" />
            </filter>
          </defs>
          <mask
            id="SVGID_3_"
            maskUnits="userSpaceOnUse"
            x="46.743"
            y="58.317"
            width="16.319"
            height="32.714"
          >
            <g filter="url(#Adobe_OpacityMaskFilter)">
              <linearGradient
                id="SVGID_4_"
                gradientUnits="userSpaceOnUse"
                x1="0"
                y1="144.1106"
                x2="1"
                y2="144.1106"
                gradientTransform="matrix(8.6169 -5.6173 5.6173 8.6169 -763.3156 -1158.4406)"
              >
                <stop offset="0" style="stop-color: #ffffff" />
                <stop offset="1" style="stop-color: #000000" />
              </linearGradient>
              <polygon
                clip-path="url(#SVGID_2_)"
                fill="url(#SVGID_4_)"
                points="42.983,169.91 161.526,92.633 84.249,-25.91 -34.294,51.367
									"
              />
            </g>
          </mask>
          <g clip-path="url(#SVGID_2_)" mask="url(#SVGID_3_)">
            <g>
              <defs>
                <rect id="SVGID_5_" x="46.743" y="58.317" width="16.319" height="32.714" />
              </defs>
              <clipPath id="SVGID_6_">
                <use xlink:href="#SVGID_5_" overflow="visible" />
              </clipPath>
              <polygon
                clip-path="url(#SVGID_6_)"
                fill="#00616F"
                points="47.743,69.337 54.582,90.032 62.062,69.955 50.733,59.317 				"
              />
            </g>
          </g>
          <defs>
            <filter
              id="Adobe_OpacityMaskFilter_1_"
              filterUnits="userSpaceOnUse"
              x="77.714"
              y="56.229"
              width="10.363"
              height="16.365"
            >
              <feFlood style="flood-color: white; flood-opacity: 1" result="back" />
              <feBlend in="SourceGraphic" in2="back" mode="normal" />
            </filter>
          </defs>
          <mask
            id="SVGID_7_"
            maskUnits="userSpaceOnUse"
            x="77.714"
            y="56.229"
            width="10.363"
            height="16.365"
          >
            <g filter="url(#Adobe_OpacityMaskFilter_1_)">
              <linearGradient
                id="SVGID_8_"
                gradientUnits="userSpaceOnUse"
                x1="0"
                y1="142.4266"
                x2="1"
                y2="142.4266"
                gradientTransform="matrix(10.6331 2.6314 2.6314 -10.6331 -305.4729 1587.8496)"
              >
                <stop offset="0" style="stop-color: #ffffff" />
                <stop offset="1" style="stop-color: #000000" />
              </linearGradient>
              <polygon
                clip-path="url(#SVGID_2_)"
                fill="url(#SVGID_8_)"
                points="18.388,-2.975 138.591,26.772 108.844,146.975
					-11.359,117.228 				"
              />
            </g>
          </mask>
          <g clip-path="url(#SVGID_2_)" mask="url(#SVGID_7_)">
            <g>
              <defs>
                <rect id="SVGID_9_" x="77.714" y="56.229" width="10.363" height="16.365" />
              </defs>
              <clipPath id="SVGID_10_">
                <use xlink:href="#SVGID_9_" overflow="visible" />
              </clipPath>
              <rect
                x="78.714"
                y="57.23"
                clip-path="url(#SVGID_10_)"
                fill="#00616F"
                width="8.362"
                height="14.365"
              />
            </g>
          </g>
          <defs>
            <filter
              id="Adobe_OpacityMaskFilter_2_"
              filterUnits="userSpaceOnUse"
              x="61.189"
              y="29.124"
              width="20.161"
              height="61.329"
            >
              <feFlood style="flood-color: white; flood-opacity: 1" result="back" />
              <feBlend in="SourceGraphic" in2="back" mode="normal" />
            </filter>
          </defs>
          <mask
            id="SVGID_11_"
            maskUnits="userSpaceOnUse"
            x="61.189"
            y="29.124"
            width="20.161"
            height="61.329"
          >
            <g filter="url(#Adobe_OpacityMaskFilter_2_)">
              <linearGradient
                id="SVGID_12_"
                gradientUnits="userSpaceOnUse"
                x1="0"
                y1="146.4459"
                x2="1"
                y2="146.4459"
                gradientTransform="matrix(8.247 3.067 -3.067 8.247 505.5992 -1138.4097)"
              >
                <stop offset="0" style="stop-color: #ffffff" />
                <stop offset="1" style="stop-color: #000000" />
              </linearGradient>
              <polygon
                clip-path="url(#SVGID_2_)"
                fill="url(#SVGID_12_)"
                points="-20.922,110.704 102.32,156.538 148.154,33.296
					24.912,-12.538 				"
              />
            </g>
          </mask>
          <g clip-path="url(#SVGID_2_)" mask="url(#SVGID_11_)">
            <g>
              <defs>
                <rect id="SVGID_13_" x="61.189" y="29.124" width="20.161" height="61.329" />
              </defs>
              <clipPath id="SVGID_14_">
                <use xlink:href="#SVGID_13_" overflow="visible" />
              </clipPath>
              <polygon
                clip-path="url(#SVGID_14_)"
                fill="#00616F"
                points="62.189,89.453 62.189,69.377 76.914,30.124 80.35,30.124 				"
              />
            </g>
          </g>
        </g>
      </g>
      <g id="vp-1">
        <polygon
          fill="#80BA27"
          points="131.664,22.002 148.276,66.678 158.47,66.678 174.893,22.002 161.993,22.002 153.373,51.262
		144.626,22.002 	"
        />
      </g>
      <g id="vp-2">
        <rect x="176.718" y="22.002" fill="#80BA27" width="12.27" height="44.676" />
      </g>
      <g id="vp-3">
        <path
          fill="#80BA27"
          d="M227.433,26.469c-1.007-0.503-2.139-1.038-3.398-1.605c-1.258-0.566-2.611-1.09-4.058-1.573
		c-1.448-0.481-2.948-0.881-4.5-1.195c-1.552-0.315-3.146-0.472-4.782-0.472c-2.224,0-4.332,0.325-6.324,0.975
		c-1.992,0.651-3.765,1.605-5.317,2.863c-1.552,1.259-2.779,2.832-3.681,4.719c-0.902,1.888-1.353,4.07-1.353,6.545
		c0,1.636,0.221,3.083,0.661,4.341c0.441,1.259,1.122,2.382,2.045,3.367c0.922,0.986,2.097,1.867,3.524,2.643
		c1.426,0.776,3.124,1.5,5.097,2.17c1.342,0.462,2.611,0.861,3.807,1.196c1.195,0.336,2.254,0.671,3.177,1.007
		s1.646,0.723,2.171,1.164c0.524,0.44,0.787,0.975,0.787,1.604c0,0.588-0.263,1.06-0.787,1.416c-0.525,0.357-1.458,0.535-2.8,0.535
		c-1.762,0-3.492-0.252-5.191-0.755c-1.699-0.503-3.22-1.07-4.562-1.699c-1.595-0.713-3.126-1.531-4.594-2.454l-5.285,10.76
		c1.342,0.797,2.8,1.51,4.373,2.139c1.573,0.63,3.209,1.175,4.908,1.636c1.699,0.462,3.408,0.818,5.128,1.07
		s3.398,0.378,5.034,0.378c2.055,0,4.1-0.242,6.135-0.724s3.859-1.268,5.475-2.36c1.614-1.09,2.915-2.548,3.901-4.373
		c0.985-1.825,1.479-4.079,1.479-6.764c0-2.097-0.315-3.88-0.944-5.349c-0.629-1.468-1.532-2.715-2.706-3.744
		c-1.175-1.027-2.591-1.919-4.247-2.674c-1.658-0.755-3.514-1.447-5.569-2.076c-1.301-0.419-2.465-0.776-3.492-1.07
		c-1.029-0.293-1.899-0.598-2.612-0.912c-0.713-0.315-1.258-0.661-1.636-1.039c-0.377-0.377-0.566-0.859-0.566-1.447
		c0-0.796,0.325-1.363,0.975-1.699c0.65-0.335,1.52-0.503,2.612-0.503c1.342,0,2.715,0.22,4.121,0.66
		c1.405,0.441,2.675,0.913,3.807,1.416c1.342,0.588,2.643,1.259,3.901,2.014L227.433,26.469z"
        />
      </g>
      <g id="vp-4">
        <rect x="232.341" y="22.002" fill="#80BA27" width="12.27" height="44.676" />
      </g>
      <g id="vp-5">
        <path
          fill="#80BA27"
          d="M261.316,40.092c0.398-1.405,1.016-2.664,1.856-3.776c0.839-1.111,1.897-2.002,3.178-2.674
		c1.279-0.67,2.8-1.007,4.562-1.007c1.719,0,3.218,0.326,4.499,0.976c1.279,0.651,2.348,1.51,3.209,2.58
		c0.859,1.069,1.499,2.307,1.919,3.712c0.419,1.406,0.629,2.863,0.629,4.373c0,1.469-0.199,2.916-0.598,4.342
		c-0.399,1.427-1.006,2.696-1.824,3.807c-0.819,1.112-1.878,2.013-3.178,2.706c-1.301,0.692-2.832,1.038-4.594,1.038
		c-1.761,0-3.282-0.335-4.562-1.007c-1.28-0.67-2.339-1.551-3.177-2.643c-0.84-1.09-1.469-2.338-1.888-3.744
		c-0.42-1.405-0.629-2.863-0.629-4.373C260.718,42.934,260.916,41.498,261.316,40.092 M280.004,65.167
		c2.789-1.258,5.191-2.926,7.205-5.002c2.013-2.077,3.586-4.468,4.719-7.174c1.133-2.705,1.699-5.526,1.699-8.463
		c0-2.852-0.535-5.642-1.605-8.369c-1.069-2.726-2.59-5.16-4.562-7.299c-1.972-2.139-4.341-3.859-7.11-5.16
		c-2.769-1.3-5.852-1.95-9.25-1.95c-3.314,0-6.366,0.619-9.155,1.856c-2.79,1.238-5.202,2.894-7.236,4.971
		c-2.036,2.076-3.618,4.478-4.751,7.205c-1.133,2.727-1.699,5.559-1.699,8.494c0,2.895,0.545,5.706,1.636,8.432
		c1.09,2.727,2.621,5.15,4.593,7.268c1.972,2.119,4.342,3.828,7.111,5.128c2.768,1.301,5.852,1.951,9.25,1.951
		C274.162,67.055,277.214,66.425,280.004,65.167"
        />
      </g>
      <g id="vp-6">
        <polygon
          fill="#80BA27"
          points="327.292,66.678 337.044,66.678 337.044,22.002 324.774,22.002 324.774,44.718 306.841,22.002
		297.276,22.002 297.276,66.678 309.546,66.678 309.546,44.718 	"
        />
      </g>
      <g id="vp-7">
        <path
          fill="#80BA27"
          d="M349.315,25.903h13.906c1.216,0,2.38,0.263,3.492,0.787c1.111,0.525,2.077,1.249,2.895,2.171
		c0.818,0.923,1.468,2.004,1.951,3.24c0.481,1.238,0.723,2.549,0.723,3.933c0,1.343-0.21,2.633-0.629,3.87
		c-0.42,1.238-1.007,2.328-1.762,3.272s-1.667,1.699-2.737,2.265c-1.07,0.567-2.256,0.85-3.555,0.85h-14.284V25.903z
		 M349.315,66.678V50.192h14.472c1.929,0,3.682-0.398,5.255-1.196c1.573-0.796,2.926-1.845,4.058-3.146
		c1.133-1.3,2.014-2.8,2.643-4.499s0.944-3.47,0.944-5.317c0-1.719-0.336-3.418-1.007-5.097c-0.671-1.677-1.595-3.177-2.769-4.499
		c-1.174-1.321-2.57-2.391-4.184-3.209c-1.615-0.818-3.388-1.227-5.317-1.227h-18.5v44.676H349.315z"
        />
      </g>
      <g id="vp-8">
        <polygon
          fill="#80BA27"
          points="412.679,66.678 412.679,62.777 386.88,62.777 386.88,22.002 382.476,22.002 382.476,66.678 	"
        />
      </g>
      <g id="vp-9">
        <path
          fill="#80BA27"
          d="M426.018,61.455c-1.846-1.048-3.325-2.432-4.436-4.153c-1.112-1.72-1.899-3.691-2.36-5.915
		c-0.462-2.223-0.692-4.509-0.692-6.858V22.002h-4.342v22.527c0,2.853,0.315,5.621,0.944,8.305c0.629,2.686,1.678,5.077,3.146,7.174
		c1.468,2.098,3.408,3.786,5.821,5.065c2.412,1.28,5.38,1.919,8.904,1.919c3.439,0,6.355-0.607,8.746-1.824
		c2.391-1.217,4.331-2.853,5.82-4.909c1.489-2.054,2.569-4.436,3.241-7.141c0.67-2.706,1.007-5.569,1.007-8.589V22.002h-4.405
		v22.527c0,2.265-0.22,4.509-0.661,6.732c-0.44,2.224-1.217,4.206-2.328,5.947c-1.112,1.741-2.601,3.146-4.467,4.216
		c-1.867,1.069-4.206,1.604-7.016,1.604C430.171,63.028,427.863,62.504,426.018,61.455"
        />
      </g>
      <g id="vp-10">
        <path
          fill="#80BA27"
          d="M489.004,26.847c-1.888-1.636-3.996-2.904-6.324-3.807c-2.328-0.901-4.982-1.353-7.96-1.353
		c-2.35,0-4.489,0.283-6.418,0.85c-1.93,0.566-3.608,1.384-5.034,2.454c-1.426,1.069-2.528,2.412-3.303,4.027
		c-0.777,1.615-1.164,3.451-1.164,5.506c0,1.804,0.303,3.325,0.912,4.562c0.608,1.238,1.52,2.296,2.737,3.177
		c1.216,0.881,2.706,1.616,4.468,2.203c1.762,0.588,3.816,1.154,6.166,1.699c2.223,0.503,4.174,1.006,5.852,1.51
		c1.678,0.503,3.083,1.101,4.216,1.793s1.982,1.51,2.549,2.454c0.566,0.944,0.849,2.108,0.849,3.492
		c0,2.602-0.997,4.542-2.989,5.821c-1.993,1.28-4.772,1.919-8.337,1.919c-1.721,0-3.357-0.157-4.908-0.472
		c-1.553-0.315-3.011-0.755-4.374-1.321c-1.363-0.567-2.601-1.227-3.712-1.982c-1.112-0.755-2.067-1.552-2.863-2.392l-2.202,3.587
		c5.033,4.321,11.011,6.481,17.933,6.481c2.349,0,4.509-0.242,6.481-0.723c1.971-0.482,3.67-1.227,5.097-2.234
		c1.425-1.007,2.537-2.265,3.335-3.776c0.796-1.51,1.195-3.292,1.195-5.348c0-2.014-0.357-3.691-1.069-5.034
		c-0.714-1.342-1.742-2.485-3.084-3.429c-1.343-0.944-2.968-1.731-4.876-2.36c-1.91-0.629-4.059-1.196-6.45-1.699
		c-2.139-0.461-3.996-0.922-5.569-1.384c-1.573-0.461-2.863-1.007-3.869-1.636c-1.007-0.629-1.752-1.374-2.234-2.234
		c-0.483-0.859-0.724-1.961-0.724-3.304c0-2.558,0.902-4.582,2.706-6.072c1.803-1.488,4.677-2.233,8.62-2.233
		c2.684,0,5.076,0.44,7.174,1.321c2.097,0.881,3.775,2.035,5.034,3.461L489.004,26.847z"
        />
      </g>
      <g id="vp-11">
        <path
          fill="#00616F"
          d="M148.896,105.184l4.244-14.146l4.051,14.146H148.896z M131.536,122.48h12.795l2.701-8.937h12.152
		l2.765,8.937h12.731l-15.817-45.652h-11.445L131.536,122.48z"
        />
      </g>
      <g id="vp-12">
        <path
          fill="#00616F"
          d="M187.161,100.306c0-3.665-2.973-6.638-6.638-6.638c-3.665,0-6.637,2.973-6.637,6.638
		c0,3.665,2.972,6.638,6.637,6.638C184.188,106.944,187.161,103.971,187.161,100.306"
        />
      </g>
      <g id="vp-13">
        <polygon
          fill="#00616F"
          points="214.23,98.625 211.079,108.141 201.178,76.828 187.611,76.828 205.485,122.48 215.902,122.48
		222.332,106.148 228.826,122.48 239.242,122.48 257.117,76.828 243.486,76.828 233.648,108.141 230.369,98.625 237.892,76.828
		226.382,76.828 222.332,90.909 218.345,76.828 206.836,76.828 	"
        />
      </g>
      <g id="vp-14">
        <path
          fill="#00616F"
          d="M268.956,100.306c0-3.665-2.973-6.638-6.638-6.638c-3.665,0-6.637,2.973-6.637,6.638
		c0,3.665,2.972,6.638,6.637,6.638C265.983,106.944,268.956,103.971,268.956,100.306"
        />
      </g>
      <g id="vp-15">
        <path
          fill="#00616F"
          d="M287.409,105.184l4.244-14.146l4.051,14.146H287.409z M270.049,122.48h12.795l2.701-8.937h12.152
		l2.765,8.937h12.731l-15.817-45.652h-11.445L270.049,122.48z"
        />
      </g>
      <g id="vp-16">
        <path
          fill="#00616F"
          d="M327.603,100.306c0-3.665-2.973-6.638-6.638-6.638c-3.665,0-6.637,2.973-6.637,6.638
		c0,3.665,2.972,6.638,6.637,6.638C324.63,106.944,327.603,103.971,327.603,100.306"
        />
      </g>
      <g id="vp-17">
        <path
          fill="#00616F"
          d="M346.507,87.759h7.587c0.772,0,1.543,0.418,2.315,1.254c0.771,0.836,1.157,2.047,1.157,3.633
		c0,1.543-0.332,2.743-0.996,3.6c-0.665,0.858-1.383,1.286-2.154,1.286h-7.909V87.759z M346.507,122.48v-14.017h4.694l8.037,14.017
		h14.146l-9.645-16.782c2.057-1.457,3.665-3.332,4.822-5.626c1.158-2.292,1.736-4.768,1.736-7.426c0-1.929-0.386-3.847-1.157-5.755
		c-0.772-1.907-1.833-3.601-3.183-5.079c-1.35-1.479-2.969-2.679-4.854-3.601c-1.887-0.921-3.945-1.383-6.173-1.383h-20.961v45.652
		H346.507z"
        />
      </g>
      <g id="vp-18">
        <path
          fill="#00616F"
          d="M387.987,100.306c0-3.665-2.973-6.638-6.638-6.638c-3.665,0-6.637,2.973-6.637,6.638
		c0,3.665,2.972,6.638,6.637,6.638C385.014,106.944,387.987,103.971,387.987,100.306"
        />
      </g>
      <g id="vp-19">
        <path
          fill="#00616F"
          d="M421.518,104.348c-0.451,1.458-1.116,2.723-1.994,3.794c-0.879,1.072-1.961,1.907-3.247,2.507
		c-1.286,0.601-2.743,0.9-4.372,0.9h-5.015v-23.79h5.015c1.585,0,3.022,0.289,4.308,0.868s2.368,1.383,3.247,2.411
		c0.878,1.029,1.553,2.273,2.025,3.73c0.472,1.457,0.708,3.065,0.708,4.822C422.193,101.305,421.968,102.891,421.518,104.348
		 M411.905,122.48c3.472,0,6.623-0.547,9.452-1.64c2.829-1.093,5.25-2.636,7.265-4.629c2.015-1.993,3.569-4.393,4.662-7.201
		c1.093-2.807,1.64-5.948,1.64-9.42c0-3.129-0.494-6.076-1.479-8.841c-0.987-2.765-2.444-5.176-4.372-7.234
		c-1.929-2.057-4.331-3.686-7.202-4.886c-2.872-1.2-6.195-1.801-9.966-1.801h-17.553v45.652H411.905z"
        />
      </g>
      <g id="vp-20">
        <path
          fill="#00616F"
          d="M451.006,100.306c0-3.665-2.973-6.638-6.638-6.638c-3.665,0-6.637,2.973-6.637,6.638
		c0,3.665,2.972,6.638,6.637,6.638C448.033,106.944,451.006,103.971,451.006,100.306"
        />
      </g>
      <g id="vp-21">
        <path
          fill="#00616F"
          d="M490.42,81.394c-1.029-0.514-2.186-1.061-3.472-1.64c-1.286-0.578-2.668-1.114-4.147-1.607
		c-1.479-0.492-3.012-0.9-4.598-1.222c-1.586-0.321-3.214-0.482-4.886-0.482c-2.273,0-4.427,0.333-6.462,0.997
		c-2.037,0.665-3.848,1.639-5.433,2.925c-1.587,1.286-2.841,2.894-3.762,4.823c-0.922,1.929-1.382,4.158-1.382,6.687
		c0,1.671,0.225,3.15,0.675,4.436s1.146,2.433,2.09,3.44c0.942,1.008,2.143,1.908,3.6,2.701c1.457,0.793,3.193,1.533,5.208,2.218
		c1.372,0.472,2.669,0.879,3.89,1.222c1.222,0.343,2.304,0.686,3.248,1.028c0.942,0.344,1.681,0.74,2.218,1.19
		c0.535,0.45,0.804,0.996,0.804,1.639c0,0.601-0.269,1.083-0.804,1.447c-0.537,0.365-1.49,0.547-2.861,0.547
		c-1.801,0-3.569-0.258-5.305-0.772c-1.736-0.514-3.29-1.093-4.662-1.736c-1.629-0.728-3.193-1.564-4.693-2.508l-5.401,10.995
		c1.371,0.815,2.861,1.544,4.468,2.186c1.608,0.643,3.279,1.201,5.016,1.672c1.736,0.472,3.482,0.836,5.24,1.093
		c1.757,0.257,3.472,0.386,5.144,0.386c2.099,0,4.189-0.247,6.269-0.739c2.078-0.493,3.943-1.296,5.594-2.412
		c1.649-1.114,2.978-2.604,3.986-4.468c1.007-1.865,1.511-4.169,1.511-6.912c0-2.143-0.321-3.965-0.964-5.466
		c-0.643-1.5-1.566-2.774-2.765-3.825c-1.201-1.05-2.647-1.961-4.34-2.733c-1.694-0.772-3.591-1.479-5.691-2.122
		c-1.329-0.428-2.518-0.792-3.568-1.093c-1.051-0.299-1.94-0.611-2.669-0.932c-0.729-0.322-1.286-0.675-1.671-1.061
		c-0.386-0.386-0.579-0.878-0.579-1.479c0-0.814,0.332-1.392,0.997-1.736c0.664-0.343,1.553-0.514,2.668-0.514
		c1.371,0,2.775,0.225,4.211,0.675s2.733,0.932,3.891,1.446c1.371,0.601,2.7,1.286,3.986,2.058L490.42,81.394z"
        />
      </g>
    </svg>
  </div>
</template>

<script setup>
import { onMounted } from 'vue'
import { gsap } from 'gsap'
onMounted(() => {
  gsap.from('.intro svg>g', { opacity: 0, scale: 3, stagger: 0.5, yoyo: true, repeat: 4 })
})
</script>

<style lang="scss">
.intro {
  display: grid;
  place-items: center;
  margin: auto;
  width: 100%;
  height: 100vh;

  svg {
    width: 100%;
    max-width: 50rem;
  }
}
</style>
