<template>
  <section id="voting" class="voting container">
    <VotingSlider />
    <Pagination />
    <Loader v-if="isLoading" />
  </section>
</template>

<script setup>
import VotingSlider from './components/voting-slider.vue'
import Pagination from './components/pagination.vue'
import Loader from '@/components/loader/index.vue'
import { useStore } from '@/stores/store'
import { onMounted } from 'vue'
const store = useStore()
onMounted(() => {
  store.isLoading = false
})
</script>
