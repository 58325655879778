<template>
  <div class="card__body">
    <CardBodyProductInfo
      :distributor-name="props.card.distributor"
      :distributor-mobile="props.card.distributorMobile"
      :brand-logo="props.card.brandLogo"
    />
    <CardBodyProductDescription
      :product-description="props.card.productDescription"
      :price="props.card.price"
    />
    <CardBodyVote v-model="votedProduct" :card="props.card" @update-vote="updateVote" />
    <CardBodySocial
      :product-code="props.card.productCode"
      :product-name="props.card.productName"
      :brand-name="props.card.brandName"
      :distributor-email="props.card.distributorEmail"
      :pdf="props.card.pdf"
    />
  </div>
</template>

<script setup>
import CardBodyProductInfo from './card-body-product-info.vue'
import CardBodyProductDescription from './card-body-product-description.vue'
import CardBodyVote from './card-body-vote.vue'
import CardBodySocial from './card-body-social.vue'
import { ref, reactive, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from '@/stores/store'

const props = defineProps({
  card: {
    type: Object,
    required: true,
  },
})

const store = useStore()
const router = useRouter()
const emit = defineEmits(['update-vote'])

const votedProduct = ref('')
const categoryVote = reactive({ categoryCode: props.card.categoryCode, productCode: null })

onMounted(() => {
  if (store.user.vote && store.user.vote[props.card.categoryCode - 1]) {
    const categoryVote = store.user.vote[props.card.categoryCode - 1]
    votedProduct.value = categoryVote.productCode
  }
})

const updateVote = () => {
  // Guard clause for user who has not logged in or without id
  if (!store.user.vote) router.push('/')

  // update the vote object's productCode to have the votedProduct code
  console.log('votedProduct', votedProduct.value)
  categoryVote.productCode = votedProduct.value

  // get currentIndex
  const currentIndex = store.currentSlide - 1

  // create tempArray to get store values
  const tempArray = [...store.user.vote]

  // update tempArray to update current vote only
  tempArray.splice(currentIndex, 1, categoryVote)

  // update store with data from tempArray
  store.user.vote = [...tempArray]

  emit('update-vote')
}
</script>
