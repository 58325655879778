<template>
  <article class="registration__message">
    <h1>Your vote has been confirmed</h1>
    <p>Thank you, {{ store.user.name }}, for participating in VP Awards 2024.</p>
    <Button @click="viewVote">View Your Votes</Button>
    <h3>
      Winners will be announced on October 9th <br />
      at VisionPlus EXPO 2024, Dubai
    </h3>
    <figure class="intro__banner-reduced" style="margin-block: 2rem">
      <a href="https://vpexpodubai.com/">
        <picture>
          <img src="@/assets/images/banner-vpexpo.jpg" alt="" />
        </picture>
      </a>
      <figcaption></figcaption>
    </figure>
  </article>
</template>
<script setup>
import Button from '@/components/form/button.vue'
import { useStore } from '@/stores/store'
import { onMounted } from 'vue'
import { mg } from '@/components/mailgun'

const store = useStore()

console.log('process', process.env.VUE_APP_BASE_URL)

onMounted(() => {
  sendRegisterEmail()
})

const viewVote = () => {
  console.log('view vote...')
  location.href = `/#/voted/${store.user.uid}`
}
const sendRegisterEmail = async () => {
  console.log('...sending email')
  const registerEmailMessage = {
    from: 'VP Awards <awards@fourplusmedia.com>',
    to: [`${store.user.email}`],
    cc: ['awards@fourplusmedia.com'],
    subject: 'Congratulations. Your votes for VP Awards 2024 has been registered',
    text: 'Your vote has been registered.',
    html: `
  <h1>Thank you for voting in VP Awards 2024</h1>
  <p> Your vote has been registered with these details </p>
  <p><strong>Name</strong> : ${store.user.name} </p>
  <p><strong>Email</strong> : ${store.user.email} </p>
  <p><strong>Mobile</strong> : ${store.user.mobile} </p>
    <p><strong>Organisation</strong> : ${store.user.organisation} </p>
    <p><strong>Nationality</strong> : ${store.user.nationality} </p>
    <p><strong>Profile</strong> : ${store.user.profile} </p>

<br/>

<p> You can visit the link below to see your votes </p>

<p><a href="${process.env.VUE_APP_BASE_URL}#/voted/${store.user.uid}">View Your Votes</a></p>

<p>In case of any technical queries regarding the voting process, please whatsapp +91-9920019569 </p>
<p>All the nominated product entries will be displayed at the VisionPlus EXPO 2024 in Dubai in the VP Awards Gallery.</p>
<p>Winners will be announced on October 9th, 2024 at an exclusive event hosted by VisionPlus EXPO 2024.</p>

<a href="https://vpexpodubai.com">
<img src="${process.env.VUE_APP_IMAGE_URL}banners/banner-vpexpo-email.jpg" style="display:inline-block;width:100%;height:auto; max-width:728px;padding-block:5px;" />
</a>

<p>To know more about VisionPlus EXPO 2024, visit https://vpexpodubai.com </p>
<p>Register as a visitor and get updates on the eyewear and optical brands exhibiting this year. <a href="https://vpexpodubai.com/visitor-registration/">Click here.</a></p>
<br/>

<p>Regards</p>
<p><strong>VP Awards Admin</strong></p>
  `,
  }

  mg.messages
    .create('mailgun.alimbolar.com', registerEmailMessage)
    .then((msg) => console.log(msg)) // logs response data
    .catch((err) => console.error(err)) // logs any error
}
</script>
<style lang="scss">
.registration__message {
  figure {
    width: 100%;
    max-width: 20rem;
    z-index: 0;
  }
  h3 {
    color: var(----secondary-color);
    margin-bottom: -2rem;
    z-index: 1;
  }
  p {
    font-size: var(--font-size-fluid-1);
  }
}
</style>
