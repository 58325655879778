<template>
  <div class="product-info">
    <figure class="product-info__image">
      <picture>
        <img :src="`${imageUrl}logos/${props.brandLogo}`" />
      </picture>
    </figure>

    <div class="product-info__content">
      <div class="distribution">
        <p class="small">Distributed by</p>
        <p>{{ props.distributorName }}</p>
      </div>
    </div>
  </div>
</template>

<script setup>
const imageUrl = process.env.VUE_APP_IMAGE_URL
const props = defineProps({
  distributorName: {
    type: String,
    default: 'John Doe',
  },
  distributorMobile: {
    type: [String, Number],
    default: '0123456789',
  },
  brandLogo: {
    type: String,
    default: 'logo.jpg',
  },
})
</script>
