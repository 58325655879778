<template>
  <section id="login" class="login container">
    <article class="login__left">
      <LoginDesktopBanner />
    </article>
    <article class="login__right">
      <LoginBanner />
      <LoginForm />
    </article>

    <Modal />
    <Loader v-if="store.isLoading" />
  </section>
</template>

<script setup>
import { onMounted, watch, ref } from 'vue'
import LoginBanner from './components/login-banner.vue'
import LoginForm from './components/login-form.vue'
import LoginDesktopBanner from './components/login-desktop.vue'
import Loader from '@/components/loader/index.vue'
import { gsap } from 'gsap'
import { useRoute } from 'vue-router'
import { useStore } from '@/stores/store'
import Modal from '@/components/modal/index.vue'

const route = useRoute()
const store = useStore()
// const loginDiv = ref(null)

onMounted(() => {
  if (route.params.errorStatus) {
    console.log('params', route.params.errorStatus)
    console.log('in errorStatus param')
    store.error.status = !!route.params.errorStatus
    store.error.headline = route.params.errorHeadline
    store.error.message = route.params.errorMessage
  }

  if (route.query.errorStatus) {
    console.log('queries', route.query.errorStatus)
    console.log('in errorStatus query')
    store.error.status = !!route.query.errorStatus
    store.error.headline = route.query.errorHeadline
    store.error.message = route.query.errorMessage
  }

  store.currentSlide = 0
  store.user.uid = ''
  store.user.name = ''
  store.user.email = ''
  store.user.mobile = ''
  store.user.organisation = ''
  store.isRegisteringUser = false
  store.isLoading = false

  gsap.timeline().to('.login__banner', {
    opacity: 0,
    duration: 2,
    delay: 1,
    scale: 0,
    ease: 'back',
    onComplete: removeBanner,
  })
})

const removeBanner = () => {
  // console.log('removing banner..')
  const banner = document.querySelector('.login__banner')
  banner?.remove()
}
</script>
