<template>
  <div class="vote">
    <div class="vote-input">
      <input
        :id="props.card.productCode"
        type="radio"
        :checked="checked"
        :value="value"
        :name="`VP-${props.card.categoryCode}`"
        data-type="vote-button"
        @change="updateVote"
      />

      <label class="vote-input__label" :for="props.card.productCode">
        <!-- <span v-if="checked" class="vote-input__check" -->
        <span class="vote-input__check"
          ><img src="@/assets/images/check-circle-icon.svg" :alt="props.card.brandName"
        /></span>
        <span class="vote-input__text"> Vote </span>
      </label>
    </div>

    <div class="vote-details">
      <p class="brand-name">
        {{ props.card.brandName }}
      </p>
      <p>{{ props.card.productName }}</p>
    </div>
  </div>
</template>

<script setup>
import { useStore } from '@/stores/store'
import { useRouter } from 'vue-router'
import { computed } from 'vue'
//
const store = useStore()
const router = useRouter()
const emit = defineEmits(['update-vote', 'update:modelValue'])
const props = defineProps({
  modelValue: {
    type: [String, Number],
    required: true,
  },
  card: {
    type: Object,
    required: true,
  },
})
//
const value = `${props.card.productCode}`
const checked = computed(() => props.modelValue === value)
//
const updateVote = () => {
  if (!store.user.uid) {
    router.push({ name: 'login' })
    return
  }
  // update value of votedProduct to v-model in parent
  // console.log('value', value)
  emit('update:modelValue', value)
  // emit updateVote to ultimately reach the parent Slide
  emit('update-vote')
}
</script>

<style>
.brand-name {
  font-weight: 700;
}
</style>
