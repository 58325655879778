<template>
  <article class="voting__arrow">
    <div :style="{ opacity: store.currentSlide <= 1 ? 0 : 1 }" class="prev" @click="prevSlide">
      <span>
        <img src="@/assets/images/left-arrow.svg" alt="" />
      </span>
    </div>
    <div class="pagination">Page {{ store.currentSlide }} of {{ store.data.length }}</div>

    <div class="next" @click="nextSlide">
      <span>
        <img src="@/assets/images/right-arrow.svg" alt="" />
      </span>
      <span>
        <img src="@/assets/images/end-arrow.svg" alt="" />
      </span>
    </div>
  </article>
  <Modal />
  <Loader v-if="store.isRegisteringUser" />
</template>

<script setup>
import { useRouter } from 'vue-router'
import { useStore } from '@/stores/store'
import Modal from '@/components/modal/index.vue'
import Loader from '@/components/loader/index.vue'
import { updateVote, moveUser } from '@/lib/firebase/index.js'
// import { createUserOnMongo } from '@/lib/mongo/index.js'

const router = useRouter()
// const route = useRoute()
const store = useStore()

const nextSlide = async () => {
  // restrict pagination if user.uid does not exist
  if (!store.user.uid) {
    console.log('ON NEXT SLIDE', store.user)
    router.push({ name: 'login' })
    return
  }
  const currentIndex = store.currentSlide - 1
  console.log('currentIndex on nextSlide', currentIndex)
  if (store.user.uid && store.user.vote && !store.user.vote[store.currentSlide - 1]) {
    store.error.status = true
    store.error.headline = 'Sorry'
    store.error.message = 'Cannot proceed without voting'
    return
  }

  const data = await registerCategoryVote()
  console.log('AFTER DATA', data)

  if (data.status === 'success') {
    // Remove user from RTDB after saving on Mongo
    // await removeUserFromRTDB(store.user.uid)
    // set(ref(db, 'users/' + store.user.uid), null)
    router.push({ name: 'register', replace: true })
    return
  }

  store.currentSlide++
}
const prevSlide = () => {
  // restrict pagination if user.uid does not exist
  if (!store.user.uid) {
    router.push({ name: 'login' })
    return
  }
  if (store.currentSlide <= 1) return
  store.currentSlide--
}

const registerCategoryVote = async () => {
  store.isLoading = true
  store.isRegisteringUser = true
  const slide = store.currentSlide

  // await createUserOnRTDB(store.user)
  await updateVote(store.user.uid, store.user.vote)

  if (store.currentSlide === store.data.length) {
    // const data = await createUserOnMongo(store.user)
    const data = await moveUser(store.user.uid)
    store.isLoading = false
    store.isRegisteringUser = false
    return data
  }

  store.isLoading = false
  store.isRegisteringUser = false

  return slide
}
</script>
// @/lib/firebase/index.js @/lib/mongo/index.js
