<template>
  <div class="card__image" @click="$emit('toggle-selection')">
    <div class="card__flip" data-id="productCode" :class="{ flipped: showProductImage }">
      <figure class="card__flip--logo">
        <picture>
          <img :src="`${imageUrl}logos/${props.card.brandLogo}`" width="400" height="200" />
        </picture>
      </figure>
      <figure
        ref="zoomBox"
        class="detail-view card__flip--product"
        :style="{
          backgroundImage: `url(${imageUrl}products/lg/${props.card.productImage})`,
        }"
        @pointermove.prevent="zoomProduct"
        @pointerleave.prevent="normaliseImage"
        @contextmenu.prevent="() => false"
      >
        <picture @contextmenu.prevent="() => false">
          <source
            :srcset="`${imageUrl}products/lg/${props.card.productImage}`"
            media="(min-width:600px)"
          />
          <img
            :src="`${imageUrl}products/lg/${props.card.productImage}`"
            :style="{ opacity: zoom ? 0 : 1 }"
            width="1200"
            height="800"
            :alt="props.card.productName"
            @contextmenu.prevent="() => false"
          />
        </picture>
      </figure>
    </div>
  </div>
</template>

<script setup>
// To be deleted.. keeping it here just as backup.. it was removed from div.card__image
// @pointerenter="showProductImage = false"
// @pointerleave="showProductImage = true"
/*
imports
*/
import { onMounted, ref, watch } from 'vue'
import { useStore } from '@/stores/store'
import { useRoute } from 'vue-router'
//

const store = useStore()

const route = useRoute()

const zoom = ref(false)

const zoomBox = ref(null)

/*
props
*/
const props = defineProps({
  card: {
    type: Object,
    required: true,
  },
  selected: {
    type: Boolean,
    required: true,
  },
  voted: {
    type: Boolean,
    required: true,
  },
})

/*
emits
*/

defineEmits(['toggle-selection'])

const imageUrl = process.env.VUE_APP_IMAGE_URL

// 'https://storage.googleapis.com/vpawards-2022.appspot.com/images/'

/*
product image flip animation for both - onload and onmouseenter
*/
const showProductImage = ref(false)
watch(
  () => store.currentSlide,
  () => {
    console.log('watching store.currentSlide', store.currentSlide, +props.card.categoryCode)
    if (store.currentSlide === +props.card.categoryCode) {
      const timeout = +props.card.productCode.split('-')[1] * 1000
      setTimeout(() => (showProductImage.value = true), timeout)
    } else {
      showProductImage.value = false
    }
  }
)

onMounted(() => {
  // console.log('image', props.card.productCode, route.params)
  // const productArray = [01, 02, 03, 04, 05]
  console.log('query on route', route.query)
  if (
    route.params.nomination ||
    route.params.category ||
    props.card.categoryCode === '01' ||
    route.query.resume === 'true'
  ) {
    const timeout = +props.card.productCode.split('-')[1] * 1000
    setTimeout(() => (showProductImage.value = true), timeout)
  }
})

const zoomProduct = function (event) {
  // Only zoom selected products that are highlighted
  if (!props.selected) return

  zoom.value = true

  const zoomBoxWidth = zoomBox.value.clientWidth
  const zoomBoxHeight = zoomBox.value.clientHeight
  const x = event.offsetX
  const y = event.offsetY

  const xPercent = Math.round((100 * x) / zoomBoxWidth)
  const yPercent = Math.round((100 * y) / zoomBoxHeight)

  zoomBox.value.style.backgroundSize = 'auto'
  zoomBox.value.style.backgroundPosition = `${xPercent}% ${yPercent}%`
  zoomBox.value.style.transition = '600ms'
}

const normaliseImage = function () {
  if (!props.selected) return
  zoom.value = false
  zoomBox.value.style.backgroundSize = 'cover'
  zoomBox.value.style.backgroundPosition = 'center'
}
</script>

<style scoped lang="scss"></style>
