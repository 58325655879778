<template>
  <form
    class="form start-vote"
    enctype="multipart/form-data"
    @submit.prevent="loginUser(store.user)"
  >
    <BaseInput
      id="name"
      v-model="store.user.name"
      label="Name"
      type="text"
      name="name"
      placeholder="Your Name"
      minlength="2"
    />
    <BaseInput
      id="email"
      v-model="store.user.email"
      label="Email"
      type="email"
      name="email"
      placeholder="Email"
    />
    <BaseIntlTelInput
      id="mobile"
      v-model="store.user.mobile"
      label="Mobile"
      type="tel"
      name="mobile"
      placeholder="Mobile"
      pattern="^(\d{8}|\d{9}|\d{10}|\d{11}|\d{12})$"
    />
    <!-- pattern="[0-9]{10,11}" -->
    <!--  -->
    <!-- <BaseInput
      id="mobile"
      v-model="store.user.mobile"
      label="Mobile"
      type="tel"
      name="mobile"
      placeholder="Mobile"
      pattern="[0-9]{10}"
    /> -->
    <BaseInput
      id="organisation"
      v-model="store.user.organisation"
      label="Organisation"
      type="text"
      name="organisation"
      placeholder="Organisation"
      minlength="2"
    />

    <BaseSelect
      id="nation"
      v-model="store.user.nationality"
      name="nationality"
      label="Nationality"
      :options="nationalities"
    />
    <BaseSelect
      id="nation"
      v-model="store.user.profile"
      name="profile"
      label="Profile"
      :options="profiles"
    />

    <!-- <button type="submit">Vote</button> -->
    <Button>Vote</Button>
  </form>
</template>

<script setup>
import { onMounted } from 'vue'
import { useStore } from '@/stores/store'
import { useRouter } from 'vue-router'
import { v4 as uuidv4 } from 'uuid'
import { validateUser } from '@/lib/utils'
import { createUserOnRTDB } from '@/lib/firebase/index.js'

import { mg } from '@/components/mailgun/index.js'

import BaseInput from '@/components/form/base-input.vue'
import BaseIntlTelInput from '@/components/form/base-intl-tel-input.vue'
import BaseSelect from '@/components/form/base-select.vue'
import Button from '@/components/form/button.vue'
import { nationalities, profiles } from '@/data/select-data'
//
const router = useRouter()
const store = useStore()

//
async function loginUser(user) {
  // Validate user

  const loggedInUser = {
    name: user.name,
    email: user.email,
    mobile: user.mobile,
    organisation: user.organisation,
    profile: user.profile,
    nationality: user.nationality,
  }

  const validation = await validateUser(loggedInUser, store)
  if (!validation) return

  // Create User UID
  user.uid = uuidv4()

  console.log('user in login', user)

  store.isLoading = true

  // Add initiatedAt Date
  user.initiatedAt = new Date().toJSON()
  // Create User on RTDB
  const data = await createUserOnRTDB(user)
  console.log('data in login', data)

  if (data.status !== 'success') {
    store.error.status = true
    store.error.headline = 'Error'
    store.error.message = 'Error in registering user. Please try again'

    return false
  }

  console.log('data in login', data)

  // Send email
  await emailMessage()

  // store.isRegisteringUser = false
  // Push to /vote
  // Corrected the line below due to this issue : https://github.com/vuejs/router/blob/main/packages/router/CHANGELOG.md#important-note
  // router.push({ name: 'vote', params: { uid: user.uid } })
  router.push({ path: `/vote/${user.uid}` })
}

const emailMessage = async () => {
  const loginMessage = {
    from: 'VP Awards <awards@fourplusmedia.com>',
    to: [`${store.user.email}`],
    cc: ['awards@fourplusmedia.com'],
    subject: 'Welcome. The voting process has been initiated',
    text: 'Your vote has been initiated.',
    html: `
  <h1>Thank you for voting in VP Awards 2024</h1>
  <p> Your votes has been registered with these details </p>
  <p><strong>Name</strong> : ${store.user.name} </p>
  <p><strong>Email</strong> : ${store.user.email} </p>
  <p><strong>Mobile</strong> : ${store.user.mobile} </p>
    <p><strong>Organisation</strong> : ${store.user.organisation} </p>
    <p><strong>Nationality</strong> : ${store.user.nationality} </p>
    <p><strong>Profile</strong> : ${store.user.profile} </p>

<br/>

<p> If you've already completed your voting, then you can ignore the link below. </p>
<p> However if you had to stop or discontinue the process then you can continue using the link below </p>

<p><a href="${process.env.VUE_APP_BASE_URL}#/vote/${store.user.uid}?resume=true">Resume Your Voting</a></p>

<p>In case of any technical queries regarding the voting process, please whatsapp +91-9920019569 </p>

<p>All the nominated product entries will be displayed at the VisionPlus EXPO 2024 in Dubai in the VP Awards Gallery.</p>

<p>Winners will be announced on October 9th, 2024 at an exclusive event hosted by VisionPlus EXPO 2024.</p>

<a href="https://vpexpodubai.com/">
<img src="${process.env.VUE_APP_IMAGE_URL}banners/banner-vpexpo-email.jpg" style="display:inline-block;width:100%;height:auto; max-width:728px;padding-block:5px;" />
</a>

<p>To know more about VisionPlus EXPO 2024, visit https://vpexpodubai.com </p>
<p>Register as a visitor and get updates on the eyewear and optical brands exhibiting this year. <a href="https://vpexpodubai.com/visitor-registration/">Click here.</a></p>
<br/>

<p>Regards</p>
<p><strong>VP Awards Admin</strong></p>
  `,
  }
  // USING MAILGUN TO SEND EMAIL
  mg.messages
    .create('mailgun.alimbolar.com', loginMessage)
    .then((msg) => console.log('sent message', msg)) // logs response data
    .catch((err) => console.error('failed to send message', err)) // logs any error
}

onMounted(() => {
  store.user.profile = 'null'
  store.user.nationality = 'null'
})
</script>
