<template>
  <!-- <div id="nav">
    <RouterLink to="/">Login</RouterLink> | <RouterLink to="/vote">Vote</RouterLink> |
    <RouterLink to="/register">Register</RouterLink>
  </div> -->
  <RouterView />
  <div id="modal"></div>
  <div id="loader"></div>
</template>

<script setup>
import { onMounted } from 'vue'
import { useStore } from './stores/store'

// import LogRocket from 'logrocket'
// LogRocket.init('fourplus-general-trading-llc/vpawards')

const store = useStore()

// Setting height of browser window to a adjust for address bar and bottom bar
// This hack is to ensure the height of the mobile view is consistent
onMounted(() => {
  let vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty('--vh', `${vh}px`)

  window.addEventListener('resize', () => {
    vh = window.innerHeight * 0.01

    document.documentElement.style.setProperty('--vh', `${vh}px`)
    // console.log(document.querySelector('body').offsetHeight)
  })

  let imageBlob
  fetch('/banner-desktop.jpg')
    .then((response) => {
      return response.blob()
    })
    .then((data) => {
      imageBlob = data
      // console.log('image blob', imageBlob)
      store.imageBlob = imageBlob
      // console.log('store imageBlob', store.imageBlob)
    })
})
</script>

<style lang="scss">
@import './assets/styles/main.scss';
@import 'open-props/style';
</style>
