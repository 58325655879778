<template>
  <div class="page-not-found">
    <figure class="logo-sm">
      <picture>
        <img src="@/assets/images/logo.png" alt="VP Awards Logo" width="400" height="100" />
      </picture>
    </figure>
    <h3>Sorry. This URL does not exist</h3>
    <a href="https://voting.vpawards.com"><p>Redirect to login page</p></a>
  </div>
</template>

<style lang="scss">
.page-not-found {
  position: fixed;
  inset: 0;
  display: grid;
  place-items: center;
  gap: 2rem;
  align-content: center;

  > h3 {
    color: var(--primary-color);
  }
  p {
    font-size: var(--font-size-1);
  }
}
</style>
